import React, { useState } from 'react';

const Faq = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const handleToggle = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <div>  
      <div className='faqbg'>
        <section id="faq" className="faq section light-background">
          <div className="container section-title text-center" data-aos="fade-up">
            <h2 className='aboutheading text-center'>Frequently Asked Questions</h2>
            <h6 className='text-center pt-2 pb-5'>
            Get answers to your questions about our holistic care
            </h6>
          </div>
          <div className="container ">
            <div className="row pt-lg-5 leftspace justify-content-center">
              <div className="col-lg-10" data-aos="fade-up" data-aos-delay="100">
                <div className="faq-container">             
                  {faqData.map((faq, index) => (
                    <div
                      key={index}
                      className={`faq-item ${activeIndex === index ? 'faq-active' : ''}`}
                      onClick={() => handleToggle(index)}
                    >
                      <h3 className='fqaheading'>{faq.question}</h3>
                      <div className="faq-content" style={{ display: activeIndex === index ? 'block' : 'none' }}>
                        <p>{faq.answer}</p>
                      </div>
                      <i className={`faq-toggle ${activeIndex === index ? 'bi bi-chevron-down' : 'bi bi-chevron-right'}`}></i>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

const faqData = [
  {
    question: "Why is your treatment expensive?",
    answer: (
      <>
 
        <a href="https://www.youtube.com/watch?v=ZlPqhidrBjc" target="_blank" className="text-decoration-none text-white">
        <i className="fas fa-video pr-2"></i>
          Watch our video to learn why our treatment is valuable and effective.
        </a>
      </>
    )
  },
  {
    question: "Does the pain return when the medication is stopped?",
    answer: (
      <>
        
        <a href="https://www.youtube.com/watch?v=8o-aK-yRW1k" target="_blank" className="text-decoration-none text-white">
        <i className="fas fa-video pr-2"></i>
          Watch our video to understand why the pain returns when the medication is stopped.
        </a>
      </>
    )
  },
  {
    question: "How does homeopathy and naturopathy work?",
    answer: (
      <>
        
        <a href="https://www.youtube.com/watch?v=pIeysB8Fvt0" target="_blank" className="text-decoration-none text-white">
        <i className="fas fa-video pr-2"></i>
          Watch our video to understand how homeopathy and naturopathy work to heal the body naturally.
        </a>
      </>
    )
  },
  {
    question: "Why do we take the fee in advance?",
    answer: (
      <>
  
        <a href="https://www.youtube.com/watch?v=yDkuPrR5H-k" target="_blank" className="text-decoration-none text-white">
        <i className="fas fa-video pr-2"></i>
          Watch our video to understand why we collect the fee upfront for your treatment.
        </a>
      </>
    )
  },
  {
    question: "What is the correct way to take homeopathic medicine?",
    answer: (
      <>
       
        <a href="https://www.youtube.com/watch?v=bi_mLoUIqVc" target="_blank" className="text-decoration-none text-white">
        <i className="fas fa-video  pr-2" ></i>
          Watch our video to learn the correct method for taking homeopathic medicine for the best results.
        </a>
      </>
    )
  },
  {
    question: "Why are your medicines proving more effective compared to other homeopathic practitioners?",
    answer: (
      <>
        
        <a href="https://www.youtube.com/watch?v=R4mr-DyqhAU" target="_blank" className="text-decoration-none text-white">
        <i className="fas fa-video pr-2"></i>
          Watch our video to learn why our homeopathic medicines are more effective than others.
        </a>
      </>
    )
  }
];

  
export default Faq;
