import React, { useEffect, useState } from 'react';
import border from '../../Assets/images/border2.png';
import { useDispatch, useSelector } from 'react-redux';
import { failed } from '../../slice/appointmentSlice';
import CommonAlert from '../../common/CommonAlert';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { useNavigate } from 'react-router-dom';
import RenderRazorpay from '../../components/payment/RenderRazorpay';
import { getorderId } from '../../slice/paymentSlice';
import { getRequest } from '../../apis/ApiInstence';
import qrcode from '../../Assets/images/qrcode.jpeg'
import slip500 from '../../Assets/images/slip500.jpg'
import slip400 from '../../Assets/images/slip400.jpg'
import slip300 from '../../Assets/images/slip300.jpg'
import { postRequest } from '../../apis/ApiInstence';

const Make_Appointment = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [paymentScreenshot, setPaymentScreenshot] = useState(null);
  const [errors, setErrors] = useState({});

  const [formSubmit, setFormSubmit] = useState(false);
  const [paymentOption, setPaymentOption] = useState(false);

  const data = useSelector(store => store.appointment);
  const order_data = useSelector(store => store.payment);

  const [booked, setBooked] = useState([]);
  const [availableDates, setAvailableDates] = useState([]);
  const [availableTimes, setAvailableTimes] = useState([]);
  const paymentModal = new window.bootstrap.Modal(document.getElementById('exampleModalCenter'));

  const [formData, setFormData] = useState({
    name: '',
    age: '',
    illness: '',
    previous_treatment: '',
    address: '',
    city: '',
    assign_doctor: '',
    appointment_date: '',
    appointment_time: '',
    appointment_type: '',
    patient_mobile: '',
    patient_mobile_whatsapp: '',
    message: '',
  });

  const validate = () => {
    const errors = {};
    if (!formData.name) {
      errors.name = "Name is required";
    }
    if (!formData.age) {
      errors.age = "Age is required";
    }
    if (!formData.illness) {
      errors.illness = "Illness is required";
    }
    if (!formData.address) {
      errors.address = "Address is required";
    }
    if (!formData.city) {
      errors.city = "City is required";
    }
    if (!formData.assign_doctor) {
      errors.assign_doctor = "Doctors is required";
    }
    if (!formData.appointment_date) {
      errors.appointment_date = "Appointment Date is required";
    }
    if (!formData.appointment_time) {
      errors.appointment_time = "Appointment Time is required";
    }
    if (!formData.appointment_type) {
      errors.appointment_type = "Appointment Type is required";
    }
    const phoneRegex = /^[0-9]{10}$/;
    if (!phoneRegex.test(formData.patient_mobile)) {
      errors.patient_mobile = "Phone number must be exactly 10 digits";
    }
    setErrors(errors);
    return Object.keys(errors).length === 0;
  };
  const getAppointments = async () => {
    const booked = await getRequest('appointments/booked')
    setBooked(booked?.data)
  }
  useEffect(() => {
    getAppointments()
  }, [])

  const DoctorData = {
    'opd': {
      location: {
        Jabalpur: [
          {
            name: "Dr. Raghav Pathak",
            dates_avaliability: [
              {
                date: "01/07/2025",
                availableTimes: [
                  "05:00 PM - 05:15 PM",
                  "05:15 PM - 05:30 PM",
                  "05:30 PM - 05:45 PM",
                  "05:45 PM - 06:00 PM",
                  "06:00 PM - 06:15 PM",
                  "06:15 PM - 06:30 PM",
                  "06:30 PM - 06:45 PM",
                  "06:45 PM - 07:00 PM",
                  "07:00 PM - 07:15 PM",
                  "07:15 PM - 07:30 PM"
                ]

              },
              {
                date: "01/08/2025",
                availableTimes: [
                  // 10:00 AM to 01:00 PM slots
                  "10:00 AM - 10:15 AM",
                  "10:15 AM - 10:30 AM",
                  "10:30 AM - 10:45 AM",
                  "10:45 AM - 11:00 AM",
                  "11:00 AM - 11:15 AM",
                  "11:15 AM - 11:30 AM",
                  "11:30 AM - 11:45 AM",
                  "11:45 AM - 12:00 PM",
                  "12:00 PM - 12:15 PM",
                  "12:15 PM - 12:30 PM",
                  "12:30 PM - 12:45 PM",
                  "12:45 PM - 01:00 PM",

                  // 03:00 PM to 06:00 PM slots
                  "03:00 PM - 03:15 PM",
                  "03:15 PM - 03:30 PM",
                  "03:30 PM - 03:45 PM",
                  "03:45 PM - 04:00 PM",
                  "04:00 PM - 04:15 PM",
                  "04:15 PM - 04:30 PM",
                  "04:30 PM - 04:45 PM",
                  "04:45 PM - 05:00 PM",
                  "05:00 PM - 05:15 PM",
                 
                ]

              },
      
            ],
          },
          {
            name: "Dr. Reena Mudiya",
            dates_avaliability: [
              {
                date: "01/01/2025",
                availableTimes: [
                  // 10:00 AM to 01:00 PM slots
                  "11:00 AM - 11:15 AM",
                  "11:15 AM - 11:30 AM",
                  "11:30 AM - 11:45 AM",
                  "11:45 AM - 12:00 PM",
                  "12:00 PM - 12:15 PM",
                  "12:15 PM - 12:30 PM",
                  "12:30 PM - 12:45 PM",
                  "12:45 PM - 01:00 PM",
                  "01:00 PM - 01:15 PM",
                  "01:15 PM - 01:30 PM",
                  "01:30 PM - 01:45 PM",
                  "01:45 PM - 02:00 PM",
                  "02:00 PM - 02:15 PM",
                  "02:15 PM - 02:30 PM",
                  "02:30 PM - 02:45 PM",
                  "02:45 PM - 03:00 PM"

                ]
              },
              {
                date: "01/02/2025",
                availableTimes: [
                  // 10:00 AM to 01:00 PM slots
                  "11:00 AM - 11:15 AM",
                  "11:15 AM - 11:30 AM",
                  "11:30 AM - 11:45 AM",
                  "11:45 AM - 12:00 PM",
                  "12:00 PM - 12:15 PM",
                  "12:15 PM - 12:30 PM",
                  "12:30 PM - 12:45 PM",
                  "12:45 PM - 01:00 PM",
                  "01:00 PM - 01:15 PM",
                  "01:15 PM - 01:30 PM",
                  "01:30 PM - 01:45 PM",
                  "01:45 PM - 02:00 PM",
                  "02:00 PM - 02:15 PM",
                  "02:15 PM - 02:30 PM",
                  "02:30 PM - 02:45 PM",
                  "02:45 PM - 03:00 PM"

                ]
              },
              {
                date: "01/03/2025",
                availableTimes: [
                  // 10:00 AM to 01:00 PM slots
                  "11:00 AM - 11:15 AM",
                  "11:15 AM - 11:30 AM",
                  "11:30 AM - 11:45 AM",
                  "11:45 AM - 12:00 PM",
                  "12:00 PM - 12:15 PM",
                  "12:15 PM - 12:30 PM",
                  "12:30 PM - 12:45 PM",
                  "12:45 PM - 01:00 PM",
                  "01:00 PM - 01:15 PM",
                  "01:15 PM - 01:30 PM",
                  "01:30 PM - 01:45 PM",
                  "01:45 PM - 02:00 PM",
                  "02:00 PM - 02:15 PM",
                  "02:15 PM - 02:30 PM",
                  "02:30 PM - 02:45 PM",
                  "02:45 PM - 03:00 PM"

                ]
              },
              {
                date: "01/04/2025",
                availableTimes: [
                  // 10:00 AM to 01:00 PM slots
                  "11:00 AM - 11:15 AM",
                  "11:15 AM - 11:30 AM",
                  "11:30 AM - 11:45 AM",
                  "11:45 AM - 12:00 PM",
                  "12:00 PM - 12:15 PM",
                  "12:15 PM - 12:30 PM",
                  "12:30 PM - 12:45 PM",
                  "12:45 PM - 01:00 PM",
                  "01:00 PM - 01:15 PM",
                  "01:15 PM - 01:30 PM",
                  "01:30 PM - 01:45 PM",
                  "01:45 PM - 02:00 PM",
                  "02:00 PM - 02:15 PM",
                  "02:15 PM - 02:30 PM",
                  "02:30 PM - 02:45 PM",
                  "02:45 PM - 03:00 PM"

                ]
              },
              // {
              //   date: "01/05/2025",
              //   availableTimes: [
              //     // 10:00 AM to 01:00 PM slots
              //     "11:00 AM - 11:15 AM",
              //     "11:15 AM - 11:30 AM",
              //     "11:30 AM - 11:45 AM",
              //     "11:45 AM - 12:00 PM",
              //     "12:00 PM - 12:15 PM",
              //     "12:15 PM - 12:30 PM",
              //     "12:30 PM - 12:45 PM",
              //     "12:45 PM - 01:00 PM",
              //     "01:00 PM - 01:15 PM",
              //     "01:15 PM - 01:30 PM",
              //     "01:30 PM - 01:45 PM",
              //     "01:45 PM - 02:00 PM",
              //     "02:00 PM - 02:15 PM",
              //     "02:15 PM - 02:30 PM",
              //     "02:30 PM - 02:45 PM",
              //     "02:45 PM - 03:00 PM"

              //   ]
              // },
              {
                date: "01/07/2025",
                availableTimes: [
                  // 10:00 AM to 01:00 PM slots
                  "11:00 AM - 11:15 AM",
                  "11:15 AM - 11:30 AM",
                  "11:30 AM - 11:45 AM",
                  "11:45 AM - 12:00 PM",
                  "12:00 PM - 12:15 PM",
                  "12:15 PM - 12:30 PM",
                  "12:30 PM - 12:45 PM",
                  "12:45 PM - 01:00 PM",
                  "01:00 PM - 01:15 PM",
                  "01:15 PM - 01:30 PM",
                  "01:30 PM - 01:45 PM",
                  "01:45 PM - 02:00 PM",
                  "02:00 PM - 02:15 PM",
                  "02:15 PM - 02:30 PM",
                  "02:30 PM - 02:45 PM",
                  "02:45 PM - 03:00 PM"

                ]
              },
              {
                date: "01/08/2025",
                availableTimes: [
                  // 10:00 AM to 01:00 PM slots
                  "11:00 AM - 11:15 AM",
                  "11:15 AM - 11:30 AM",
                  "11:30 AM - 11:45 AM",
                  "11:45 AM - 12:00 PM",
                  "12:00 PM - 12:15 PM",
                  "12:15 PM - 12:30 PM",
                  "12:30 PM - 12:45 PM",
                  "12:45 PM - 01:00 PM",
                  "01:00 PM - 01:15 PM",
                  "01:15 PM - 01:30 PM",
                  "01:30 PM - 01:45 PM",
                  "01:45 PM - 02:00 PM",
                  "02:00 PM - 02:15 PM",
                  "02:15 PM - 02:30 PM",
                  "02:30 PM - 02:45 PM",
                  "02:45 PM - 03:00 PM"

                ]
              },
         
              {
                date: "01/09/2025",
                availableTimes: [
                  // 10:00 AM to 01:00 PM slots
                  "11:00 AM - 11:15 AM",
                  "11:15 AM - 11:30 AM",
                  "11:30 AM - 11:45 AM",
                  "11:45 AM - 12:00 PM",
                  "12:00 PM - 12:15 PM",
                  "12:15 PM - 12:30 PM",
                  "12:30 PM - 12:45 PM",
                  "12:45 PM - 01:00 PM",
                  "01:00 PM - 01:15 PM",
                  "01:15 PM - 01:30 PM",
                  "01:30 PM - 01:45 PM",
                  "01:45 PM - 02:00 PM",
                  "02:00 PM - 02:15 PM",
                  "02:15 PM - 02:30 PM",
                  "02:30 PM - 02:45 PM",
                  "02:45 PM - 03:00 PM"

                ]
              },
              {
                date: "01/10/2025",
                availableTimes: [
                  // 10:00 AM to 01:00 PM slots
                  "11:00 AM - 11:15 AM",
                  "11:15 AM - 11:30 AM",
                  "11:30 AM - 11:45 AM",
                  "11:45 AM - 12:00 PM",
                  "12:00 PM - 12:15 PM",
                  "12:15 PM - 12:30 PM",
                  "12:30 PM - 12:45 PM",
                  "12:45 PM - 01:00 PM",
                  "01:00 PM - 01:15 PM",
                  "01:15 PM - 01:30 PM",
                  "01:30 PM - 01:45 PM",
                  "01:45 PM - 02:00 PM",
                  "02:00 PM - 02:15 PM",
                  "02:15 PM - 02:30 PM",
                  "02:30 PM - 02:45 PM",
                  "02:45 PM - 03:00 PM"

                ]
              },
              {
                date: "01/11/2025",
                availableTimes: [
                  // 10:00 AM to 01:00 PM slots
                  "11:00 AM - 11:15 AM",
                  "11:15 AM - 11:30 AM",
                  "11:30 AM - 11:45 AM",
                  "11:45 AM - 12:00 PM",
                  "12:00 PM - 12:15 PM",
                  "12:15 PM - 12:30 PM",
                  "12:30 PM - 12:45 PM",
                  "12:45 PM - 01:00 PM",
                  "01:00 PM - 01:15 PM",
                  "01:15 PM - 01:30 PM",
                  "01:30 PM - 01:45 PM",
                  "01:45 PM - 02:00 PM",
                  "02:00 PM - 02:15 PM",
                  "02:15 PM - 02:30 PM",
                  "02:30 PM - 02:45 PM",
                  "02:45 PM - 03:00 PM"

                ]
              },
              // {
              //   date: "01/12/2025",
              //   availableTimes: [
              //     // 10:00 AM to 01:00 PM slots
              //     "11:00 AM - 11:15 AM",
              //     "11:15 AM - 11:30 AM",
              //     "11:30 AM - 11:45 AM",
              //     "11:45 AM - 12:00 PM",
              //     "12:00 PM - 12:15 PM",
              //     "12:15 PM - 12:30 PM",
              //     "12:30 PM - 12:45 PM",
              //     "12:45 PM - 01:00 PM",
              //     "01:00 PM - 01:15 PM",
              //     "01:15 PM - 01:30 PM",
              //     "01:30 PM - 01:45 PM",
              //     "01:45 PM - 02:00 PM",
              //     "02:00 PM - 02:15 PM",
              //     "02:15 PM - 02:30 PM",
              //     "02:30 PM - 02:45 PM",
              //     "02:45 PM - 03:00 PM"

              //   ]
              // },
              {
                date: "01/13/2025",
                availableTimes: [
                  // 10:00 AM to 01:00 PM slots
                  "11:00 AM - 11:15 AM",
                  "11:15 AM - 11:30 AM",
                  "11:30 AM - 11:45 AM",
                  "11:45 AM - 12:00 PM",
                  "12:00 PM - 12:15 PM",
                  "12:15 PM - 12:30 PM",
                  "12:30 PM - 12:45 PM",
                  "12:45 PM - 01:00 PM",
                  "01:00 PM - 01:15 PM",
                  "01:15 PM - 01:30 PM",
                  "01:30 PM - 01:45 PM",
                  "01:45 PM - 02:00 PM",
                  "02:00 PM - 02:15 PM",
                  "02:15 PM - 02:30 PM",
                  "02:30 PM - 02:45 PM",
                  "02:45 PM - 03:00 PM"

                ]
              },
              {
                date: "01/14/2025",
                availableTimes: [
                  // 10:00 AM to 01:00 PM slots
                  "11:00 AM - 11:15 AM",
                  "11:15 AM - 11:30 AM",
                  "11:30 AM - 11:45 AM",
                  "11:45 AM - 12:00 PM",
                  "12:00 PM - 12:15 PM",
                  "12:15 PM - 12:30 PM",
                  "12:30 PM - 12:45 PM",
                  "12:45 PM - 01:00 PM",
                  "01:00 PM - 01:15 PM",
                  "01:15 PM - 01:30 PM",
                  "01:30 PM - 01:45 PM",
                  "01:45 PM - 02:00 PM",
                  "02:00 PM - 02:15 PM",
                  "02:15 PM - 02:30 PM",
                  "02:30 PM - 02:45 PM",
                  "02:45 PM - 03:00 PM"

                ]
              },
              {
                date: "01/15/2025",
                availableTimes: [
                  // 10:00 AM to 01:00 PM slots
                  "11:00 AM - 11:15 AM",
                  "11:15 AM - 11:30 AM",
                  "11:30 AM - 11:45 AM",
                  "11:45 AM - 12:00 PM",
                  "12:00 PM - 12:15 PM",
                  "12:15 PM - 12:30 PM",
                  "12:30 PM - 12:45 PM",
                  "12:45 PM - 01:00 PM",
                  "01:00 PM - 01:15 PM",
                  "01:15 PM - 01:30 PM",
                  "01:30 PM - 01:45 PM",
                  "01:45 PM - 02:00 PM",
                  "02:00 PM - 02:15 PM",
                  "02:15 PM - 02:30 PM",
                  "02:30 PM - 02:45 PM",
                  "02:45 PM - 03:00 PM"

                ]
              },
              {
                date: "01/16/2025",
                availableTimes: [
                  // 10:00 AM to 01:00 PM slots
                  "11:00 AM - 11:15 AM",
                  "11:15 AM - 11:30 AM",
                  "11:30 AM - 11:45 AM",
                  "11:45 AM - 12:00 PM",
                  "12:00 PM - 12:15 PM",
                  "12:15 PM - 12:30 PM",
                  "12:30 PM - 12:45 PM",
                  "12:45 PM - 01:00 PM",
                  "01:00 PM - 01:15 PM",
                  "01:15 PM - 01:30 PM",
                  "01:30 PM - 01:45 PM",
                  "01:45 PM - 02:00 PM",
                  "02:00 PM - 02:15 PM",
                  "02:15 PM - 02:30 PM",
                  "02:30 PM - 02:45 PM",
                  "02:45 PM - 03:00 PM"

                ]
              },
              {
                date: "01/17/2025",
                availableTimes: [
                  // 10:00 AM to 01:00 PM slots
                  "11:00 AM - 11:15 AM",
                  "11:15 AM - 11:30 AM",
                  "11:30 AM - 11:45 AM",
                  "11:45 AM - 12:00 PM",
                  "12:00 PM - 12:15 PM",
                  "12:15 PM - 12:30 PM",
                  "12:30 PM - 12:45 PM",
                  "12:45 PM - 01:00 PM",
                  "01:00 PM - 01:15 PM",
                  "01:15 PM - 01:30 PM",
                  "01:30 PM - 01:45 PM",
                  "01:45 PM - 02:00 PM",
                  "02:00 PM - 02:15 PM",
                  "02:15 PM - 02:30 PM",
                  "02:30 PM - 02:45 PM",
                  "02:45 PM - 03:00 PM"

                ]
              },
              {
                date: "01/18/2025",
                availableTimes: [
                  // 10:00 AM to 01:00 PM slots
                  "11:00 AM - 11:15 AM",
                  "11:15 AM - 11:30 AM",
                  "11:30 AM - 11:45 AM",
                  "11:45 AM - 12:00 PM",
                  "12:00 PM - 12:15 PM",
                  "12:15 PM - 12:30 PM",
                  "12:30 PM - 12:45 PM",
                  "12:45 PM - 01:00 PM",
                  "01:00 PM - 01:15 PM",
                  "01:15 PM - 01:30 PM",
                  "01:30 PM - 01:45 PM",
                  "01:45 PM - 02:00 PM",
                  "02:00 PM - 02:15 PM",
                  "02:15 PM - 02:30 PM",
                  "02:30 PM - 02:45 PM",
                  "02:45 PM - 03:00 PM"

                ]
              },
              // {
              //   date: "01/19/2025",
              //   availableTimes: [
              //     // 10:00 AM to 01:00 PM slots
              //     "11:00 AM - 11:15 AM",
              //     "11:15 AM - 11:30 AM",
              //     "11:30 AM - 11:45 AM",
              //     "11:45 AM - 12:00 PM",
              //     "12:00 PM - 12:15 PM",
              //     "12:15 PM - 12:30 PM",
              //     "12:30 PM - 12:45 PM",
              //     "12:45 PM - 01:00 PM",
              //     "01:00 PM - 01:15 PM",
              //     "01:15 PM - 01:30 PM",
              //     "01:30 PM - 01:45 PM",
              //     "01:45 PM - 02:00 PM",
              //     "02:00 PM - 02:15 PM",
              //     "02:15 PM - 02:30 PM",
              //     "02:30 PM - 02:45 PM",
              //     "02:45 PM - 03:00 PM"

              //   ]
              // },
              {
                date: "01/20/2025",
                availableTimes: [
                  // 10:00 AM to 01:00 PM slots
                  "11:00 AM - 11:15 AM",
                  "11:15 AM - 11:30 AM",
                  "11:30 AM - 11:45 AM",
                  "11:45 AM - 12:00 PM",
                  "12:00 PM - 12:15 PM",
                  "12:15 PM - 12:30 PM",
                  "12:30 PM - 12:45 PM",
                  "12:45 PM - 01:00 PM",
                  "01:00 PM - 01:15 PM",
                  "01:15 PM - 01:30 PM",
                  "01:30 PM - 01:45 PM",
                  "01:45 PM - 02:00 PM",
                  "02:00 PM - 02:15 PM",
                  "02:15 PM - 02:30 PM",
                  "02:30 PM - 02:45 PM",
                  "02:45 PM - 03:00 PM"

                ]
              },
              {
                date: "01/21/2025",
                availableTimes: [
                  // 10:00 AM to 01:00 PM slots
                  "11:00 AM - 11:15 AM",
                  "11:15 AM - 11:30 AM",
                  "11:30 AM - 11:45 AM",
                  "11:45 AM - 12:00 PM",
                  "12:00 PM - 12:15 PM",
                  "12:15 PM - 12:30 PM",
                  "12:30 PM - 12:45 PM",
                  "12:45 PM - 01:00 PM",
                  "01:00 PM - 01:15 PM",
                  "01:15 PM - 01:30 PM",
                  "01:30 PM - 01:45 PM",
                  "01:45 PM - 02:00 PM",
                  "02:00 PM - 02:15 PM",
                  "02:15 PM - 02:30 PM",
                  "02:30 PM - 02:45 PM",
                  "02:45 PM - 03:00 PM"

                ]
              },
              {
                date: "01/22/2025",
                availableTimes: [
                  // 10:00 AM to 01:00 PM slots
                  "11:00 AM - 11:15 AM",
                  "11:15 AM - 11:30 AM",
                  "11:30 AM - 11:45 AM",
                  "11:45 AM - 12:00 PM",
                  "12:00 PM - 12:15 PM",
                  "12:15 PM - 12:30 PM",
                  "12:30 PM - 12:45 PM",
                  "12:45 PM - 01:00 PM",
                  "01:00 PM - 01:15 PM",
                  "01:15 PM - 01:30 PM",
                  "01:30 PM - 01:45 PM",
                  "01:45 PM - 02:00 PM",
                  "02:00 PM - 02:15 PM",
                  "02:15 PM - 02:30 PM",
                  "02:30 PM - 02:45 PM",
                  "02:45 PM - 03:00 PM"

                ]
              },
              {
                date: "01/23/2025",
                availableTimes: [
                  // 10:00 AM to 01:00 PM slots
                  "11:00 AM - 11:15 AM",
                  "11:15 AM - 11:30 AM",
                  "11:30 AM - 11:45 AM",
                  "11:45 AM - 12:00 PM",
                  "12:00 PM - 12:15 PM",
                  "12:15 PM - 12:30 PM",
                  "12:30 PM - 12:45 PM",
                  "12:45 PM - 01:00 PM",
                  "01:00 PM - 01:15 PM",
                  "01:15 PM - 01:30 PM",
                  "01:30 PM - 01:45 PM",
                  "01:45 PM - 02:00 PM",
                  "02:00 PM - 02:15 PM",
                  "02:15 PM - 02:30 PM",
                  "02:30 PM - 02:45 PM",
                  "02:45 PM - 03:00 PM"

                ]
              },
              {
                date: "01/28/2025",
                availableTimes: [
                  // 10:00 AM to 01:00 PM slots
                  "11:00 AM - 11:15 AM",
                  "11:15 AM - 11:30 AM",
                  "11:30 AM - 11:45 AM",
                  "11:45 AM - 12:00 PM",
                  "12:00 PM - 12:15 PM",
                  "12:15 PM - 12:30 PM",
                  "12:30 PM - 12:45 PM",
                  "12:45 PM - 01:00 PM",
                  "01:00 PM - 01:15 PM",
                  "01:15 PM - 01:30 PM",
                  "01:30 PM - 01:45 PM",
                  "01:45 PM - 02:00 PM",
                  "02:00 PM - 02:15 PM",
                  "02:15 PM - 02:30 PM",
                  "02:30 PM - 02:45 PM",
                  "02:45 PM - 03:00 PM"

                ]
              },
              {
                date: "01/29/2025",
                availableTimes: [
                  // 10:00 AM to 01:00 PM slots
                  "11:00 AM - 11:15 AM",
                  "11:15 AM - 11:30 AM",
                  "11:30 AM - 11:45 AM",
                  "11:45 AM - 12:00 PM",
                  "12:00 PM - 12:15 PM",
                  "12:15 PM - 12:30 PM",
                  "12:30 PM - 12:45 PM",
                  "12:45 PM - 01:00 PM",
                  "01:00 PM - 01:15 PM",
                  "01:15 PM - 01:30 PM",
                  "01:30 PM - 01:45 PM",
                  "01:45 PM - 02:00 PM",
                  "02:00 PM - 02:15 PM",
                  "02:15 PM - 02:30 PM",
                  "02:30 PM - 02:45 PM",
                  "02:45 PM - 03:00 PM"

                ]
              },
              {
                date: "01/30/2025",
                availableTimes: [
                  // 10:00 AM to 01:00 PM slots
                  "11:00 AM - 11:15 AM",
                  "11:15 AM - 11:30 AM",
                  "11:30 AM - 11:45 AM",
                  "11:45 AM - 12:00 PM",
                  "12:00 PM - 12:15 PM",
                  "12:15 PM - 12:30 PM",
                  "12:30 PM - 12:45 PM",
                  "12:45 PM - 01:00 PM",
                  "01:00 PM - 01:15 PM",
                  "01:15 PM - 01:30 PM",
                  "01:30 PM - 01:45 PM",
                  "01:45 PM - 02:00 PM",
                  "02:00 PM - 02:15 PM",
                  "02:15 PM - 02:30 PM",
                  "02:30 PM - 02:45 PM",
                  "02:45 PM - 03:00 PM"

                ]
              },
              {
                date: "01/31/2025",
                availableTimes: [
                  // 10:00 AM to 01:00 PM slots
                  "11:00 AM - 11:15 AM",
                  "11:15 AM - 11:30 AM",
                  "11:30 AM - 11:45 AM",
                  "11:45 AM - 12:00 PM",
                  "12:00 PM - 12:15 PM",
                  "12:15 PM - 12:30 PM",
                  "12:30 PM - 12:45 PM",
                  "12:45 PM - 01:00 PM",
                  "01:00 PM - 01:15 PM",
                  "01:15 PM - 01:30 PM",
                  "01:30 PM - 01:45 PM",
                  "01:45 PM - 02:00 PM",
                  "02:00 PM - 02:15 PM",
                  "02:15 PM - 02:30 PM",
                  "02:30 PM - 02:45 PM",
                  "02:45 PM - 03:00 PM"

                ]
              },
            ],
          }
        ],
        rewa: [
          {
            name: "Dr. Raghav Pathak",
            dates_avaliability: [
              {
                date: "01/25/2025",
                availableTimes: [
                  // 10:00 AM to 01:00 PM slots
                  "10:00 AM - 10:15 AM",
                  "10:15 AM - 10:30 AM",
                  "10:30 AM - 10:45 AM",
                  "10:45 AM - 11:00 AM",
                  "11:00 AM - 11:15 AM",
                  "11:15 AM - 11:30 AM",
                  "11:30 AM - 11:45 AM",
                  "11:45 AM - 12:00 PM",
                  "12:00 PM - 12:15 PM",
                  "12:15 PM - 12:30 PM",
                  "12:30 PM - 12:45 PM",
                  "12:45 PM - 01:00 PM",

                  // 03:00 PM to 06:00 PM slots
                  "03:00 PM - 03:15 PM",
                  "03:15 PM - 03:30 PM",
                  "03:30 PM - 03:45 PM",
                  "03:45 PM - 04:00 PM",
                  "04:00 PM - 04:15 PM",
                  "04:15 PM - 04:30 PM",
                  "04:30 PM - 04:45 PM",
                  "04:45 PM - 05:00 PM",
                ]

              },
              {
                date: "01/26/2025",
                availableTimes: [
                  // 10:00 AM to 01:00 PM slots
                  "10:00 AM - 10:15 AM",
                  "10:15 AM - 10:30 AM",
                  "10:30 AM - 10:45 AM",
                  "10:45 AM - 11:00 AM",
                  "11:00 AM - 11:15 AM",
                  "11:15 AM - 11:30 AM",
                  "11:30 AM - 11:45 AM",
                  "11:45 AM - 12:00 PM",
                  "12:00 PM - 12:15 PM",
                  "12:15 PM - 12:30 PM",
                  "12:30 PM - 12:45 PM",
                  "12:45 PM - 01:00 PM",

                  // 03:00 PM to 06:00 PM slots
                  "03:00 PM - 03:15 PM",
                  "03:15 PM - 03:30 PM",
                  "03:30 PM - 03:45 PM",
                  "03:45 PM - 04:00 PM",
                  "04:00 PM - 04:15 PM",
                  "04:15 PM - 04:30 PM",
                  "04:30 PM - 04:45 PM",
                  "04:45 PM - 05:00 PM",
              
                ]
              },
          
            ],
          },
          {
            name: "Dr. Reena Mudiya",
            dates_avaliability: [
              {
                date: "01/25/2025",
                availableTimes: [
                  // 10:00 AM to 01:00 PM slots
                  "10:00 AM - 10:15 AM",
                  "10:15 AM - 10:30 AM",
                  "10:30 AM - 10:45 AM",
                  "10:45 AM - 11:00 AM",
                  "11:00 AM - 11:15 AM",
                  "11:15 AM - 11:30 AM",
                  "11:30 AM - 11:45 AM",
                  "11:45 AM - 12:00 PM",
                  "12:00 PM - 12:15 PM",
                  "12:15 PM - 12:30 PM",
                  "12:30 PM - 12:45 PM",
                  "12:45 PM - 01:00 PM",

                  // 03:00 PM to 06:00 PM slots
                  "03:00 PM - 03:15 PM",
                  "03:15 PM - 03:30 PM",
                  "03:30 PM - 03:45 PM",
                  "03:45 PM - 04:00 PM",
                  "04:00 PM - 04:15 PM",
                  "04:15 PM - 04:30 PM",
                  "04:30 PM - 04:45 PM",
                  "04:45 PM - 05:00 PM",
                ]

              },
              {
                date: "01/26/2025",
                availableTimes: [
                  // 10:00 AM to 01:00 PM slots
                  "10:00 AM - 10:15 AM",
                  "10:15 AM - 10:30 AM",
                  "10:30 AM - 10:45 AM",
                  "10:45 AM - 11:00 AM",
                  "11:00 AM - 11:15 AM",
                  "11:15 AM - 11:30 AM",
                  "11:30 AM - 11:45 AM",
                  "11:45 AM - 12:00 PM",
                  "12:00 PM - 12:15 PM",
                  "12:15 PM - 12:30 PM",
                  "12:30 PM - 12:45 PM",
                  "12:45 PM - 01:00 PM",

                  // 03:00 PM to 06:00 PM slots
                  "03:00 PM - 03:15 PM",
                  "03:15 PM - 03:30 PM",
                  "03:30 PM - 03:45 PM",
                  "03:45 PM - 04:00 PM",
                  "04:00 PM - 04:15 PM",
                  "04:15 PM - 04:30 PM",
                  "04:30 PM - 04:45 PM",
                  "04:45 PM - 05:00 PM",
                  
                ]
              },
           
            ],
          },
        ],
        khajuraho: [
          {
            name: "Dr. Raghav Pathak",
            dates_avaliability: [
              {
                date: "01/03/2025",
                availableTimes: [
                  "11:00 AM - 11:15 AM",
                  "11:15 AM - 11:30 AM",
                  "11:30 AM - 11:45 AM",
                  "11:45 AM - 12:00 PM",
                  "12:00 PM - 12:15 PM",
                  "12:15 PM - 12:30 PM",
                  "12:30 PM - 12:45 PM",
                  "12:45 PM - 01:00 PM",
                  "01:00 PM - 01:15 PM",
                  "01:15 PM - 01:30 PM",
                  "01:30 PM - 01:45 PM",
                  "01:45 PM - 02:00 PM",
                ]

              },
              {
                date: "01/14/2025",
                availableTimes: [
                  // 11:00 AM to 02:00 PM slots
                  "11:00 AM - 11:15 AM",
                  "11:15 AM - 11:30 AM",
                  "11:30 AM - 11:45 AM",
                  "11:45 AM - 12:00 PM",
                  "12:00 PM - 12:15 PM",
                  "12:15 PM - 12:30 PM",
                  "12:30 PM - 12:45 PM",
                  "12:45 PM - 01:00 PM",
                  "11:00 AM - 11:15 AM",
                  "11:15 AM - 11:30 AM",
                  "11:30 AM - 11:45 AM",
                  "11:45 AM - 12:00 PM",
                  "12:00 PM - 12:15 PM",
                  "12:15 PM - 12:30 PM",
                  "12:30 PM - 12:45 PM",
                  "12:45 PM - 01:00 PM",
                  "01:00 PM - 01:15 PM",
                  "01:15 PM - 01:30 PM",
                  "01:30 PM - 01:45 PM",
                  "01:45 PM - 02:00 PM",
                ]

              },
              {
                date: "01/21/2025",
                availableTimes: [
                  "11:00 AM - 11:15 AM",
                  "11:15 AM - 11:30 AM",
                  "11:30 AM - 11:45 AM",
                  "11:45 AM - 12:00 PM",
                  "12:00 PM - 12:15 PM",
                  "12:15 PM - 12:30 PM",
                  "12:30 PM - 12:45 PM",
                  "12:45 PM - 01:00 PM",
                  "01:00 PM - 01:15 PM",
                  "01:15 PM - 01:30 PM",
                  "01:30 PM - 01:45 PM",
                  "01:45 PM - 02:00 PM",
                ]

              },
            ],
          },
          {
            name: "Dr. Ruchi Gupta",
            dates_avaliability: [
              {
                date: "1/1/2025",
                availableTimes: [
                    "11:00 AM - 11:15 AM",
                    "11:15 AM - 11:30 AM",
                    "11:30 AM - 11:45 AM",
                    "11:45 AM - 12:00 PM",
                    "12:00 PM - 12:15 PM",
                    "12:15 PM - 12:30 PM",
                    "12:30 PM - 12:45 PM",
                    "12:45 PM - 1:00 PM",
                    "1:00 PM - 1:15 PM",
                    "1:15 PM - 1:30 PM",
                    "1:30 PM - 1:45 PM",
                    "1:45 PM - 2:00 PM",
                    "2:00 PM - 2:15 PM",
                    "2:15 PM - 2:30 PM",
                    "2:30 PM - 2:45 PM",
                    "2:45 PM - 3:00 PM",
                ]
            },
            {
                date: "1/2/2025",
                availableTimes: [
                    "11:00 AM - 11:15 AM",
                    "11:15 AM - 11:30 AM",
                    "11:30 AM - 11:45 AM",
                    "11:45 AM - 12:00 PM",
                    "12:00 PM - 12:15 PM",
                    "12:15 PM - 12:30 PM",
                    "12:30 PM - 12:45 PM",
                    "12:45 PM - 1:00 PM",
                    "1:00 PM - 1:15 PM",
                    "1:15 PM - 1:30 PM",
                    "1:30 PM - 1:45 PM",
                    "1:45 PM - 2:00 PM",
                    "2:00 PM - 2:15 PM",
                    "2:15 PM - 2:30 PM",
                    "2:30 PM - 2:45 PM",
                    "2:45 PM - 3:00 PM",
                ]
            },
            {
                date: "1/3/2025",
                availableTimes: [
                    "11:00 AM - 11:15 AM",
                    "11:15 AM - 11:30 AM",
                    "11:30 AM - 11:45 AM",
                    "11:45 AM - 12:00 PM",
                    "12:00 PM - 12:15 PM",
                    "12:15 PM - 12:30 PM",
                    "12:30 PM - 12:45 PM",
                    "12:45 PM - 1:00 PM",
                    "1:00 PM - 1:15 PM",
                    "1:15 PM - 1:30 PM",
                    "1:30 PM - 1:45 PM",
                    "1:45 PM - 2:00 PM",
                    "2:00 PM - 2:15 PM",
                    "2:15 PM - 2:30 PM",
                    "2:30 PM - 2:45 PM",
                    "2:45 PM - 3:00 PM",
                ]
            },
            {
                date: "1/4/2025",
                availableTimes: [
                    "11:00 AM - 11:15 AM",
                    "11:15 AM - 11:30 AM",
                    "11:30 AM - 11:45 AM",
                    "11:45 AM - 12:00 PM",
                    "12:00 PM - 12:15 PM",
                    "12:15 PM - 12:30 PM",
                    "12:30 PM - 12:45 PM",
                    "12:45 PM - 1:00 PM",
                    "1:00 PM - 1:15 PM",
                    "1:15 PM - 1:30 PM",
                    "1:30 PM - 1:45 PM",
                    "1:45 PM - 2:00 PM",
                    "2:00 PM - 2:15 PM",
                    "2:15 PM - 2:30 PM",
                    "2:30 PM - 2:45 PM",
                    "2:45 PM - 3:00 PM",
                ]
            },
          //   {
          //     date: "1/5/2025",
          //     availableTimes: [
          //         "11:00 AM - 11:15 AM",
          //         "11:15 AM - 11:30 AM",
          //         "11:30 AM - 11:45 AM",
          //         "11:45 AM - 12:00 PM",
          //         "12:00 PM - 12:15 PM",
          //         "12:15 PM - 12:30 PM",
          //         "12:30 PM - 12:45 PM",
          //         "12:45 PM - 1:00 PM",
          //         "1:00 PM - 1:15 PM",
          //         "1:15 PM - 1:30 PM",
          //         "1:30 PM - 1:45 PM",
          //         "1:45 PM - 2:00 PM",
          //         "2:00 PM - 2:15 PM",
          //         "2:15 PM - 2:30 PM",
          //         "2:30 PM - 2:45 PM",
          //         "2:45 PM - 3:00 PM",
          //     ]
          // },
          {
              date: "1/6/2025",
              availableTimes: [
                  "11:00 AM - 11:15 AM",
                  "11:15 AM - 11:30 AM",
                  "11:30 AM - 11:45 AM",
                  "11:45 AM - 12:00 PM",
                  "12:00 PM - 12:15 PM",
                  "12:15 PM - 12:30 PM",
                  "12:30 PM - 12:45 PM",
                  "12:45 PM - 1:00 PM",
                  "1:00 PM - 1:15 PM",
                  "1:15 PM - 1:30 PM",
                  "1:30 PM - 1:45 PM",
                  "1:45 PM - 2:00 PM",
                  "2:00 PM - 2:15 PM",
                  "2:15 PM - 2:30 PM",
                  "2:30 PM - 2:45 PM",
                  "2:45 PM - 3:00 PM",
              ]
          },
          {
              date: "1/7/2025",
              availableTimes: [
                  "11:00 AM - 11:15 AM",
                  "11:15 AM - 11:30 AM",
                  "11:30 AM - 11:45 AM",
                  "11:45 AM - 12:00 PM",
                  "12:00 PM - 12:15 PM",
                  "12:15 PM - 12:30 PM",
                  "12:30 PM - 12:45 PM",
                  "12:45 PM - 1:00 PM",
                  "1:00 PM - 1:15 PM",
                  "1:15 PM - 1:30 PM",
                  "1:30 PM - 1:45 PM",
                  "1:45 PM - 2:00 PM",
                  "2:00 PM - 2:15 PM",
                  "2:15 PM - 2:30 PM",
                  "2:30 PM - 2:45 PM",
                  "2:45 PM - 3:00 PM",
              ]
          },
          {
              date: "1/8/2025",
              availableTimes: [
                  "11:00 AM - 11:15 AM",
                  "11:15 AM - 11:30 AM",
                  "11:30 AM - 11:45 AM",
                  "11:45 AM - 12:00 PM",
                  "12:00 PM - 12:15 PM",
                  "12:15 PM - 12:30 PM",
                  "12:30 PM - 12:45 PM",
                  "12:45 PM - 1:00 PM",
                  "1:00 PM - 1:15 PM",
                  "1:15 PM - 1:30 PM",
                  "1:30 PM - 1:45 PM",
                  "1:45 PM - 2:00 PM",
                  "2:00 PM - 2:15 PM",
                  "2:15 PM - 2:30 PM",
                  "2:30 PM - 2:45 PM",
                  "2:45 PM - 3:00 PM",
              ]
          },
          {
            date: "1/9/2025",
            availableTimes: [
                "11:00 AM - 11:15 AM",
                "11:15 AM - 11:30 AM",
                "11:30 AM - 11:45 AM",
                "11:45 AM - 12:00 PM",
                "12:00 PM - 12:15 PM",
                "12:15 PM - 12:30 PM",
                "12:30 PM - 12:45 PM",
                "12:45 PM - 1:00 PM",
                "1:00 PM - 1:15 PM",
                "1:15 PM - 1:30 PM",
                "1:30 PM - 1:45 PM",
                "1:45 PM - 2:00 PM",
                "2:00 PM - 2:15 PM",
                "2:15 PM - 2:30 PM",
                "2:30 PM - 2:45 PM",
                "2:45 PM - 3:00 PM",
            ]
        },
        {
            date: "1/10/2025",
            availableTimes: [
                "11:00 AM - 11:15 AM",
                "11:15 AM - 11:30 AM",
                "11:30 AM - 11:45 AM",
                "11:45 AM - 12:00 PM",
                "12:00 PM - 12:15 PM",
                "12:15 PM - 12:30 PM",
                "12:30 PM - 12:45 PM",
                "12:45 PM - 1:00 PM",
                "1:00 PM - 1:15 PM",
                "1:15 PM - 1:30 PM",
                "1:30 PM - 1:45 PM",
                "1:45 PM - 2:00 PM",
                "2:00 PM - 2:15 PM",
                "2:15 PM - 2:30 PM",
                "2:30 PM - 2:45 PM",
                "2:45 PM - 3:00 PM",
            ]
        },
        {
            date: "1/11/2025",
            availableTimes: [
                "11:00 AM - 11:15 AM",
                "11:15 AM - 11:30 AM",
                "11:30 AM - 11:45 AM",
                "11:45 AM - 12:00 PM",
                "12:00 PM - 12:15 PM",
                "12:15 PM - 12:30 PM",
                "12:30 PM - 12:45 PM",
                "12:45 PM - 1:00 PM",
                "1:00 PM - 1:15 PM",
                "1:15 PM - 1:30 PM",
                "1:30 PM - 1:45 PM",
                "1:45 PM - 2:00 PM",
                "2:00 PM - 2:15 PM",
                "2:15 PM - 2:30 PM",
                "2:30 PM - 2:45 PM",
                "2:45 PM - 3:00 PM",
            ] 
        },
        // {
        //     date: "1/12/2025",
        //     availableTimes: [
        //         "11:00 AM - 11:15 AM",
        //         "11:15 AM - 11:30 AM",
        //         "11:30 AM - 11:45 AM",
        //         "11:45 AM - 12:00 PM",
        //         "12:00 PM - 12:15 PM",
        //         "12:15 PM - 12:30 PM",
        //         "12:30 PM - 12:45 PM",
        //         "12:45 PM - 1:00 PM",
        //         "1:00 PM - 1:15 PM",
        //         "1:15 PM - 1:30 PM",
        //         "1:30 PM - 1:45 PM",
        //         "1:45 PM - 2:00 PM",
        //         "2:00 PM - 2:15 PM",
        //         "2:15 PM - 2:30 PM",
        //         "2:30 PM - 2:45 PM",
        //         "2:45 PM - 3:00 PM",
        //     ]
        // },
        {
          date: "1/13/2025",
          availableTimes: [
              "11:00 AM - 11:15 AM",
              "11:15 AM - 11:30 AM",
              "11:30 AM - 11:45 AM",
              "11:45 AM - 12:00 PM",
              "12:00 PM - 12:15 PM",
              "12:15 PM - 12:30 PM",
              "12:30 PM - 12:45 PM",
              "12:45 PM - 1:00 PM",
              "1:00 PM - 1:15 PM",
              "1:15 PM - 1:30 PM",
              "1:30 PM - 1:45 PM",
              "1:45 PM - 2:00 PM",
              "2:00 PM - 2:15 PM",
              "2:15 PM - 2:30 PM",
              "2:30 PM - 2:45 PM",
              "2:45 PM - 3:00 PM",
          ]
      },
      {
          date: "1/14/2025",
          availableTimes: [
              "11:00 AM - 11:15 AM",
              "11:15 AM - 11:30 AM",
              "11:30 AM - 11:45 AM",
              "11:45 AM - 12:00 PM",
              "12:00 PM - 12:15 PM",
              "12:15 PM - 12:30 PM",
              "12:30 PM - 12:45 PM",
              "12:45 PM - 1:00 PM",
              "1:00 PM - 1:15 PM",
              "1:15 PM - 1:30 PM",
              "1:30 PM - 1:45 PM",
              "1:45 PM - 2:00 PM",
              "2:00 PM - 2:15 PM",
              "2:15 PM - 2:30 PM",
              "2:30 PM - 2:45 PM",
              "2:45 PM - 3:00 PM",
          ]
      },  
      {
        date: "1/18/2025",
        availableTimes: [
            "11:00 AM - 11:15 AM",
            "11:15 AM - 11:30 AM",
            "11:30 AM - 11:45 AM",
            "11:45 AM - 12:00 PM",
            "12:00 PM - 12:15 PM",
            "12:15 PM - 12:30 PM",
            "12:30 PM - 12:45 PM",
            "12:45 PM - 1:00 PM",
            "1:00 PM - 1:15 PM",
            "1:15 PM - 1:30 PM",
            "1:30 PM - 1:45 PM",
            "1:45 PM - 2:00 PM",
            "2:00 PM - 2:15 PM",
            "2:15 PM - 2:30 PM",
            "2:30 PM - 2:45 PM",
            "2:45 PM - 3:00 PM",
        ]
    },
    // {
    //     date: "1/19/2025",
    //     availableTimes: [
    //         "11:00 AM - 11:15 AM",
    //         "11:15 AM - 11:30 AM",
    //         "11:30 AM - 11:45 AM",
    //         "11:45 AM - 12:00 PM",
    //         "12:00 PM - 12:15 PM",
    //         "12:15 PM - 12:30 PM",
    //         "12:30 PM - 12:45 PM",
    //         "12:45 PM - 1:00 PM",
    //         "1:00 PM - 1:15 PM",
    //         "1:15 PM - 1:30 PM",
    //         "1:30 PM - 1:45 PM",
    //         "1:45 PM - 2:00 PM",
    //         "2:00 PM - 2:15 PM",
    //         "2:15 PM - 2:30 PM",
    //         "2:30 PM - 2:45 PM",
    //         "2:45 PM - 3:00 PM",
    //     ]
    // },
    {
        date: "1/20/2025",
        availableTimes: [
            "11:00 AM - 11:15 AM",
            "11:15 AM - 11:30 AM",
            "11:30 AM - 11:45 AM",
            "11:45 AM - 12:00 PM",
            "12:00 PM - 12:15 PM",
            "12:15 PM - 12:30 PM",
            "12:30 PM - 12:45 PM",
            "12:45 PM - 1:00 PM",
            "1:00 PM - 1:15 PM",
            "1:15 PM - 1:30 PM",
            "1:30 PM - 1:45 PM",
            "1:45 PM - 2:00 PM",
            "2:00 PM - 2:15 PM",
            "2:15 PM - 2:30 PM",
            "2:30 PM - 2:45 PM",
            "2:45 PM - 3:00 PM",
        ]
    },
    {
        date: "1/21/2025",
        availableTimes: [
            "11:00 AM - 11:15 AM",
            "11:15 AM - 11:30 AM",
            "11:30 AM - 11:45 AM",
            "11:45 AM - 12:00 PM",
            "12:00 PM - 12:15 PM",
            "12:15 PM - 12:30 PM",
            "12:30 PM - 12:45 PM",
            "12:45 PM - 1:00 PM",
            "1:00 PM - 1:15 PM",
            "1:15 PM - 1:30 PM",
            "1:30 PM - 1:45 PM",
            "1:45 PM - 2:00 PM",
            "2:00 PM - 2:15 PM",
            "2:15 PM - 2:30 PM",
            "2:30 PM - 2:45 PM",
            "2:45 PM - 3:00 PM",
        ]
    },
    {
      date: "1/22/2025",
      availableTimes: [
          "11:00 AM - 11:15 AM",
          "11:15 AM - 11:30 AM",
          "11:30 AM - 11:45 AM",
          "11:45 AM - 12:00 PM",
          "12:00 PM - 12:15 PM",
          "12:15 PM - 12:30 PM",
          "12:30 PM - 12:45 PM",
          "12:45 PM - 1:00 PM",
          "1:00 PM - 1:15 PM",
          "1:15 PM - 1:30 PM",
          "1:30 PM - 1:45 PM",
          "1:45 PM - 2:00 PM",
          "2:00 PM - 2:15 PM",
          "2:15 PM - 2:30 PM",
          "2:30 PM - 2:45 PM",
          "2:45 PM - 3:00 PM",
      ]
  },
  {
      date: "1/23/2025",
      availableTimes: [
          "11:00 AM - 11:15 AM",
          "11:15 AM - 11:30 AM",
          "11:30 AM - 11:45 AM",
          "11:45 AM - 12:00 PM",
          "12:00 PM - 12:15 PM",
          "12:15 PM - 12:30 PM",
          "12:30 PM - 12:45 PM",
          "12:45 PM - 1:00 PM",
          "1:00 PM - 1:15 PM",
          "1:15 PM - 1:30 PM",
          "1:30 PM - 1:45 PM",
          "1:45 PM - 2:00 PM",
          "2:00 PM - 2:15 PM",
          "2:15 PM - 2:30 PM",
          "2:30 PM - 2:45 PM",
          "2:45 PM - 3:00 PM",
      ]
  },
  {
      date: "1/24/2025",
      availableTimes: [
          "11:00 AM - 11:15 AM",
          "11:15 AM - 11:30 AM",
          "11:30 AM - 11:45 AM",
          "11:45 AM - 12:00 PM",
          "12:00 PM - 12:15 PM",
          "12:15 PM - 12:30 PM",
          "12:30 PM - 12:45 PM",
          "12:45 PM - 1:00 PM",
          "1:00 PM - 1:15 PM",
          "1:15 PM - 1:30 PM",
          "1:30 PM - 1:45 PM",
          "1:45 PM - 2:00 PM",
          "2:00 PM - 2:15 PM",
          "2:15 PM - 2:30 PM",
          "2:30 PM - 2:45 PM",
          "2:45 PM - 3:00 PM",
      ]
  },
  {
    date: "1/25/2025",
    availableTimes: [
        "11:00 AM - 11:15 AM",
        "11:15 AM - 11:30 AM",
        "11:30 AM - 11:45 AM",
        "11:45 AM - 12:00 PM",
        "12:00 PM - 12:15 PM",
        "12:15 PM - 12:30 PM",
        "12:30 PM - 12:45 PM",
        "12:45 PM - 1:00 PM",
        "1:00 PM - 1:15 PM",
        "1:15 PM - 1:30 PM",
        "1:30 PM - 1:45 PM",
        "1:45 PM - 2:00 PM",
        "2:00 PM - 2:15 PM",
        "2:15 PM - 2:30 PM",
        "2:30 PM - 2:45 PM",
        "2:45 PM - 3:00 PM",
    ]
},
// {
//     date: "1/26/2025",
//     availableTimes: [
//         "11:00 AM - 11:15 AM",
//         "11:15 AM - 11:30 AM",
//         "11:30 AM - 11:45 AM",
//         "11:45 AM - 12:00 PM",
//         "12:00 PM - 12:15 PM",
//         "12:15 PM - 12:30 PM",
//         "12:30 PM - 12:45 PM",
//         "12:45 PM - 1:00 PM",
//         "1:00 PM - 1:15 PM",
//         "1:15 PM - 1:30 PM",
//         "1:30 PM - 1:45 PM",
//         "1:45 PM - 2:00 PM",
//         "2:00 PM - 2:15 PM",
//         "2:15 PM - 2:30 PM",
//         "2:30 PM - 2:45 PM",
//         "2:45 PM - 3:00 PM",
//     ]
// },
{
    date: "1/27/2025",
    availableTimes: [
        "11:00 AM - 11:15 AM",
        "11:15 AM - 11:30 AM",
        "11:30 AM - 11:45 AM",
        "11:45 AM - 12:00 PM",
        "12:00 PM - 12:15 PM",
        "12:15 PM - 12:30 PM",
        "12:30 PM - 12:45 PM",
        "12:45 PM - 1:00 PM",
        "1:00 PM - 1:15 PM",
        "1:15 PM - 1:30 PM",
        "1:30 PM - 1:45 PM",
        "1:45 PM - 2:00 PM",
        "2:00 PM - 2:15 PM",
        "2:15 PM - 2:30 PM",
        "2:30 PM - 2:45 PM",
        "2:45 PM - 3:00 PM",
    ]
},
{
    date: "1/28/2025",
    availableTimes: [
        "11:00 AM - 11:15 AM",
        "11:15 AM - 11:30 AM",
        "11:30 AM - 11:45 AM",
        "11:45 AM - 12:00 PM",
        "12:00 PM - 12:15 PM",
        "12:15 PM - 12:30 PM",
        "12:30 PM - 12:45 PM",
        "12:45 PM - 1:00 PM",
        "1:00 PM - 1:15 PM",
        "1:15 PM - 1:30 PM",
        "1:30 PM - 1:45 PM",
        "1:45 PM - 2:00 PM",
        "2:00 PM - 2:15 PM",
        "2:15 PM - 2:30 PM",
        "2:30 PM - 2:45 PM",
        "2:45 PM - 3:00 PM",
    ]
},
{
  date: "1/29/2025",
  availableTimes: [
      "11:00 AM - 11:15 AM",
      "11:15 AM - 11:30 AM",
      "11:30 AM - 11:45 AM",
      "11:45 AM - 12:00 PM",
      "12:00 PM - 12:15 PM",
      "12:15 PM - 12:30 PM",
      "12:30 PM - 12:45 PM",
      "12:45 PM - 1:00 PM",
      "1:00 PM - 1:15 PM",
      "1:15 PM - 1:30 PM",
      "1:30 PM - 1:45 PM",
      "1:45 PM - 2:00 PM",
      "2:00 PM - 2:15 PM",
      "2:15 PM - 2:30 PM",
      "2:30 PM - 2:45 PM",
      "2:45 PM - 3:00 PM",
  ]
},
            ],
          }
        ],
        chhatarpur: [
          {
            name: "Dr. Raghav Pathak",
            dates_avaliability: [
              {
                date: "01/15/2025",
                availableTimes: [
                  // 10:00 AM to 01:00 PM slots
                  "10:00 AM - 10:15 AM",
                  "10:15 AM - 10:30 AM",
                  "10:30 AM - 10:45 AM",
                  "10:45 AM - 11:00 AM",
                  "11:00 AM - 11:15 AM",
                  "11:15 AM - 11:30 AM",
                  "11:30 AM - 11:45 AM",
                  "11:45 AM - 12:00 PM",
                  "12:00 PM - 12:15 PM",
                  "12:15 PM - 12:30 PM",
                  "12:30 PM - 12:45 PM",
                  "12:45 PM - 01:00 PM",

                  // 03:00 PM to 06:00 PM slots
                  "03:00 PM - 03:15 PM",
                  "03:15 PM - 03:30 PM",
                  "03:30 PM - 03:45 PM",
                  "03:45 PM - 04:00 PM",
                  "04:00 PM - 04:15 PM",
                  "04:15 PM - 04:30 PM",
                  "04:30 PM - 04:45 PM",
                  "04:45 PM - 05:00 PM",
                ]

              },
              {
                date: "01/16/2025",
                availableTimes: [
                  // 10:00 AM to 01:00 PM slots
                  "10:00 AM - 10:15 AM",
                  "10:15 AM - 10:30 AM",
                  "10:30 AM - 10:45 AM",
                  "10:45 AM - 11:00 AM",
                  "11:00 AM - 11:15 AM",
                  "11:15 AM - 11:30 AM",
                  "11:30 AM - 11:45 AM",
                  "11:45 AM - 12:00 PM",
                  "12:00 PM - 12:15 PM",
                  "12:15 PM - 12:30 PM",
                  "12:30 PM - 12:45 PM",
                  "12:45 PM - 01:00 PM",

                  // 03:00 PM to 06:00 PM slots
                  "03:00 PM - 03:15 PM",
                  "03:15 PM - 03:30 PM",
                  "03:30 PM - 03:45 PM",
                  "03:45 PM - 04:00 PM",
                  "04:00 PM - 04:15 PM",
                  "04:15 PM - 04:30 PM",
                  "04:30 PM - 04:45 PM",
                  "04:45 PM - 05:00 PM",
                
                ]
              },
              {
                date: "01/17/2025",
                availableTimes: [
                  // 10:00 AM to 01:00 PM slots
                  "10:00 AM - 10:15 AM",
                  "10:15 AM - 10:30 AM",
                  "10:30 AM - 10:45 AM",
                  "10:45 AM - 11:00 AM",
                  "11:00 AM - 11:15 AM",
                  "11:15 AM - 11:30 AM",
                  "11:30 AM - 11:45 AM",
                  "11:45 AM - 12:00 PM",
                  "12:00 PM - 12:15 PM",
                  "12:15 PM - 12:30 PM",
                  "12:30 PM - 12:45 PM",
                  "12:45 PM - 01:00 PM",

                  // 03:00 PM to 06:00 PM slots
                  "03:00 PM - 03:15 PM",
                  "03:15 PM - 03:30 PM",
                  "03:30 PM - 03:45 PM",
                  "03:45 PM - 04:00 PM",
                  "04:00 PM - 04:15 PM",
                  "04:15 PM - 04:30 PM",
                  "04:30 PM - 04:45 PM",
                  "04:45 PM - 05:00 PM",
                
                
                ]
              },
              {
                date: "01/30/2025",
                availableTimes: [
                  // 10:00 AM to 01:00 PM slots
                  "10:00 AM - 10:15 AM",
                  "10:15 AM - 10:30 AM",
                  "10:30 AM - 10:45 AM",
                  "10:45 AM - 11:00 AM",
                  "11:00 AM - 11:15 AM",
                  "11:15 AM - 11:30 AM",
                  "11:30 AM - 11:45 AM",
                  "11:45 AM - 12:00 PM",
                  "12:00 PM - 12:15 PM",
                  "12:15 PM - 12:30 PM",
                  "12:30 PM - 12:45 PM",
                  "12:45 PM - 01:00 PM",

                  // 03:00 PM to 06:00 PM slots
                  "03:00 PM - 03:15 PM",
                  "03:15 PM - 03:30 PM",
                  "03:30 PM - 03:45 PM",
                  "03:45 PM - 04:00 PM",
                  "04:00 PM - 04:15 PM",
                  "04:15 PM - 04:30 PM",
                  "04:30 PM - 04:45 PM",
                  "04:45 PM - 05:00 PM",
                 
                ]
              },
            ],
          },
           {
            name: "Dr. Ruchi Gupta",
            dates_avaliability: [
              {
                date: "01/15/2025",
                availableTimes: [
                  "11:00 AM - 11:15 AM",
                  "11:15 AM - 11:30 AM",
                  "11:30 AM - 11:45 AM",
                  "11:45 AM - 12:00 PM",
                  "12:00 PM - 12:15 PM",
                  "12:15 PM - 12:30 PM",
                  "12:30 PM - 12:45 PM",
                  "12:45 PM - 01:00 PM",
                  "01:00 PM - 01:15 PM",
                  "01:15 PM - 01:30 PM",
                  "01:30 PM - 01:45 PM",
                  "01:45 PM - 02:00 PM",
                  "02:00 PM - 02:15 PM",
                  "02:15 PM - 02:30 PM",
                  "02:30 PM - 02:45 PM",
                  "02:45 PM - 03:00 PM"
                ]

              },
              {
                date: "1/16/2025",
                availableTimes: [
                  "11:00 AM - 11:15 AM",
                  "11:15 AM - 11:30 AM",
                  "11:30 AM - 11:45 AM",
                  "11:45 AM - 12:00 PM",
                  "12:00 PM - 12:15 PM",
                  "12:15 PM - 12:30 PM",
                  "12:30 PM - 12:45 PM",
                  "12:45 PM - 01:00 PM",
                  "01:00 PM - 01:15 PM",
                  "01:15 PM - 01:30 PM",
                  "01:30 PM - 01:45 PM",
                  "01:45 PM - 02:00 PM",
                  "02:00 PM - 02:15 PM",
                  "02:15 PM - 02:30 PM",
                  "02:30 PM - 02:45 PM",
                  "02:45 PM - 03:00 PM"
                ]
              },
              {
                date: "1/17/2025",
                availableTimes: [
                  "11:00 AM - 11:15 AM",
                  "11:15 AM - 11:30 AM",
                  "11:30 AM - 11:45 AM",
                  "11:45 AM - 12:00 PM",
                  "12:00 PM - 12:15 PM",
                  "12:15 PM - 12:30 PM",
                  "12:30 PM - 12:45 PM",
                  "12:45 PM - 01:00 PM",
                  "01:00 PM - 01:15 PM",
                  "01:15 PM - 01:30 PM",
                  "01:30 PM - 01:45 PM",
                  "01:45 PM - 02:00 PM",
                  "02:00 PM - 02:15 PM",
                  "02:15 PM - 02:30 PM",
                  "02:30 PM - 02:45 PM",
                  "02:45 PM - 03:00 PM"
                ]
              },
              {
                date: "01/30/2025",
                availableTimes: [
                  "11:00 AM - 11:15 AM",
                  "11:15 AM - 11:30 AM",
                  "11:30 AM - 11:45 AM",
                  "11:45 AM - 12:00 PM",
                  "12:00 PM - 12:15 PM",
                  "12:15 PM - 12:30 PM",
                  "12:30 PM - 12:45 PM",
                  "12:45 PM - 01:00 PM",
                  "01:00 PM - 01:15 PM",
                  "01:15 PM - 01:30 PM",
                  "01:30 PM - 01:45 PM",
                  "01:45 PM - 02:00 PM",
                  "02:00 PM - 02:15 PM",
                  "02:15 PM - 02:30 PM",
                  "02:30 PM - 02:45 PM",
                  "02:45 PM - 03:00 PM"
                ]
              },
            ],
          },
        ],
        pendra: [
          {
            name: "Dr. Raghav Pathak",
            dates_avaliability: [
              {
                date: "01/23/2025",
                availableTimes: [
                  // 10:00 AM to 01:00 PM slots
                  "10:00 AM - 10:15 AM",
                  "10:15 AM - 10:30 AM",
                  "10:30 AM - 10:45 AM",
                  "10:45 AM - 11:00 AM",
                  "11:00 AM - 11:15 AM",
                  "11:15 AM - 11:30 AM",
                  "11:30 AM - 11:45 AM",
                  "11:45 AM - 12:00 PM",
                  "12:00 PM - 12:15 PM",
                  "12:15 PM - 12:30 PM",
                  "12:30 PM - 12:45 PM",
                  "12:45 PM - 01:00 PM",

                  // 03:00 PM to 06:00 PM slots
                  "03:00 PM - 03:15 PM",
                  "03:15 PM - 03:30 PM",
                  "03:30 PM - 03:45 PM",
                  "03:45 PM - 04:00 PM",
                  "04:00 PM - 04:15 PM",
                  "04:15 PM - 04:30 PM",
                  "04:30 PM - 04:45 PM",
                  "04:45 PM - 05:00 PM",
              
                ]

              },
            ],
          }
        ],
        narsinghpur: [
          {
            name: "Dr. Raghav Pathak",
            dates_avaliability: [
              {
                date: "01/06/2025",
                availableTimes: [
                  // 10:00 AM to 01:00 PM slots
                  "10:00 AM - 10:15 AM",
                  "10:15 AM - 10:30 AM",
                  "10:30 AM - 10:45 AM",
                  "10:45 AM - 11:00 AM",
                  "11:00 AM - 11:15 AM",
                  "11:15 AM - 11:30 AM",
                  "11:30 AM - 11:45 AM",
                  "11:45 AM - 12:00 PM",
                  "12:00 PM - 12:15 PM",
                  "12:15 PM - 12:30 PM",
                  "12:30 PM - 12:45 PM",
                  "12:45 PM - 01:00 PM",

                  // 03:00 PM to 06:00 PM slots
                  "03:00 PM - 03:15 PM",
                  "03:15 PM - 03:30 PM",
                  "03:30 PM - 03:45 PM",
                  "03:45 PM - 04:00 PM",
                  "04:00 PM - 04:15 PM",
                  "04:15 PM - 04:30 PM",
                  "04:30 PM - 04:45 PM",
                  "04:45 PM - 05:00 PM",
                 
                ]
              },
              {
                date: "06/12/2024",
                availableTimes: [
                  // 10:00 AM to 01:00 PM slots
                  "10:00 AM - 10:15 AM",
                  "10:15 AM - 10:30 AM",
                  "10:30 AM - 10:45 AM",
                  "10:45 AM - 11:00 AM",
                  "11:00 AM - 11:15 AM",
                  "11:15 AM - 11:30 AM",
                  "11:30 AM - 11:45 AM",
                  "11:45 AM - 12:00 PM",
                  "12:00 PM - 12:15 PM",
                  "12:15 PM - 12:30 PM",
                  "12:30 PM - 12:45 PM",
                  "12:45 PM - 01:00 PM",

                  // 03:00 PM to 06:00 PM slots
                  "03:00 PM - 03:15 PM",
                  "03:15 PM - 03:30 PM",
                  "03:30 PM - 03:45 PM",
                  "03:45 PM - 04:00 PM",
                  "04:00 PM - 04:15 PM",
                  "04:15 PM - 04:30 PM",
                  "04:30 PM - 04:45 PM",
                  "04:45 PM - 05:00 PM",
                  "05:00 PM - 05:15 PM",
                  "05:15 PM - 05:30 PM",
                  "05:30 PM - 05:45 PM",
                  "05:45 PM - 06:00 PM"
                ]

              },
            ],
          },
          {
            name: "Dr. Reena Mudiya",
            dates_avaliability: [
              {
                date: "01/06/2025",
                availableTimes: [
                  "11:00 AM - 11:15 AM",
                  "11:15 AM - 11:30 AM",
                  "11:30 AM - 11:45 AM",
                  "11:45 AM - 12:00 PM",
                  "12:00 PM - 12:15 PM",
                  "12:15 PM - 12:30 PM",
                  "12:30 PM - 12:45 PM",
                  "12:45 PM - 01:00 PM",
                  "01:00 PM - 01:15 PM",
                  "01:15 PM - 01:30 PM",
                  "01:30 PM - 01:45 PM",
                  "01:45 PM - 02:00 PM",
                  "02:00 PM - 02:15 PM",
                  "02:15 PM - 02:30 PM",
                  "02:30 PM - 02:45 PM",
                  "02:45 PM - 03:00 PM"
                ]
              },
            ],
          }
        ],
      }
    },
    'tele-medicine': {
      location: {
        'mobile call': [
          {
            name: "Dr. Reena Mudiya",
            dates_avaliability: [
              {
                date: "all",
                availableTimes: [
                  "02:00 PM - 02:15 PM",
                  "02:15 PM - 02:30 PM",
                  "02:30 PM - 02:45 PM",
                  "02:45 PM - 03:00 PM"
                ]
              },
            ],
          },
          {
            name: "Dr. Ruchi Gupta",
            dates_avaliability: [
              {
                date: "all",
                availableTimes: [
                  "02:00 PM - 02:15 PM",
                  "02:15 PM - 02:30 PM",
                  "02:30 PM - 02:45 PM",
                  "02:45 PM - 03:00 PM"
                ]
              },
            ],
          }
        ],
        'virtual (zoom/google meet)': [
          {
            name: "Dr. Reena Mudiya",
            dates_avaliability: [
              {
                date: "all",
                availableTimes: [
                  "02:00 PM - 02:15 PM",
                  "02:15 PM - 02:30 PM",
                  "02:30 PM - 02:45 PM",
                  "02:45 PM - 03:00 PM"
                ]
              },
            ],
          },
          {
            name: "Dr. Ruchi Gupta",
            dates_avaliability: [
              {
                date: "all",
                availableTimes: [
                  "02:00 PM - 02:15 PM",
                  "02:15 PM - 02:30 PM",
                  "02:30 PM - 02:45 PM",
                  "02:45 PM - 03:00 PM"
                ]
              },
            ],
          }
        ]
      }
    }
  }

  useEffect(() => {
    const dates_data_doctor = formData?.appointment_type ? DoctorData[formData?.appointment_type]['location'][formData?.city].find((item) => {
      return item?.name === formData?.assign_doctor
    }) : []

    let available_date = dates_data_doctor?.dates_avaliability ? dates_data_doctor?.dates_avaliability : null;
    setAvailableDates(available_date)
  }, [formData?.assign_doctor])

  const handleChange = (e) => {
    const { name, value, type } = e.target;

    if (name === 'assign_doctor') {
      setFormData((prev) => ({
        ...prev,
        appointment_date: null,
        [name]: type === 'file' ? e.target.files[0] : value,
      }));
    } else {
      setFormData((prev) => ({
        ...prev,
        [name]: type === 'file' ? e.target.files[0] : value,
      }));
    }


  };


  const handleSubmitOnline = async (e) => {
    e.preventDefault();
    let header = {
      'Content-Type': 'multipart/form-data'
    }

    const formDataToSend = new FormData();
    for (const key in formData) {
      formDataToSend.append(key, formData[key]);
    }

    const response = await postRequest(`appointments`, formDataToSend, header);
    if (response?.data) {
      window.location.href = `/receipt/${response?.data?.id}`
    }
  }


  // const handleSubmitOnline = (e) => {
  //   e.preventDefault();
  //   dispatch(getorderId({
  //     amount: 250,
  //     currency: 'INR',
  //   }));
  //   setPaymentOption(true);
  // }
  const onConfirm = () => {
    navigate(`/receipt/${data?.response?.id}`);
    dispatch(failed());
  };

  const handleDateChange = (date) => {
    const adjustedDate = new Date(date.getTime() - date.getTimezoneOffset() * 60000);
    setFormData((prev) => ({
      ...prev,
      appointment_date: adjustedDate
    }));

    // const formattedDate = date.toISOString().split("T")[0];
    const formattedDate = date.toLocaleDateString('en-IN')

    const selectedDateData = availableDates.find(
      (item) => {
        if (item.date === 'all') {
          return true;
        }
        const d1 = new Date(item.date)
        return d1.getTime() === date.getTime()
      }
    );

    if (selectedDateData) {
      setAvailableTimes(selectedDateData.availableTimes);
    } else {
      setAvailableTimes(["10AM - 6PM"]);
    }
  };

  const isDateAndTimeAvailable = (selectedDate, selectedTime) => {
    return booked?.some((appointment) => {
      const appointmentDate = new Date(appointment.appointment_date);
      const selectedDateFormatted = new Date(selectedDate);

      // Strip time from both dates for comparison
      const appointmentDateOnly = new Date(appointmentDate.getFullYear(), appointmentDate.getMonth(), appointmentDate.getDate());
      const selectedDateOnly = new Date(selectedDateFormatted.getFullYear(), selectedDateFormatted.getMonth(), selectedDateFormatted.getDate());

      if (appointmentDateOnly.getTime() === selectedDateOnly.getTime() && appointment.appointment_time === selectedTime) {
        return true;
      }
    });
  };


  const openPopUp = (showData) => {

    if (showData && validate()) {
      paymentModal.show();
    }
    else {
      paymentModal.hide();
    }
  }
  // Model logic
  // const handleScreenshotChange = (e) => {
  //   setPaymentScreenshot(e.target.files[0]);
  // };

  // const handleSubmit = async (e) => {
  //   e.preventDefault();=
  //   if (validate()) {
  //     const paymentModal = new window.bootstrap.Modal(document.getElementById('exampleModalCenter'));
  //     paymentModal.show();
  //   }
  // };

  // const handlePaymentSubmit = async () => {
  //   if (!paymentScreenshot) {
  //     alert('Please upload a payment screenshot before proceeding.');
  //     return;
  //   }

  //   const formDataToSend = new FormData();
  //   for (const key in formData) {
  //     formDataToSend.append(key, formData[key]);
  //   }
  //   formDataToSend.append('paymentScreenshot', paymentScreenshot);

  //   try {
  //     const response = await postRequest(process.env.REACT_APP_BACKEND_URL + 'donations', formDataToSend);
  //     if (response.status === 201) {
  //       setIsSubmitted(true);
  //       const paymentModal = window.bootstrap.Modal.getInstance(document.getElementById('exampleModalCenter'));
  //       paymentModal.hide();
  //       setFormData({
  //         name: '',
  //         phone: '',
  //         message: '',
  //       });
  //       setPaymentScreenshot(null);
  //     }
  //   } catch (error) {
  //     console.error('Submission error:', error);
  //     dispatch(failed());
  //   }
  // }

  return (
    <div>
      {data.response?.id && (
        <CommonAlert
          title="Appointment Booked Successfully"
          text="We will contact you soon"
          icon="success"
          confirmButtonText="Ok"
          onConfirm={onConfirm}
        />
      )}
      {paymentOption && order_data?.response?.length && (
        <RenderRazorpay
          orderId={order_data?.response?.[0]?.order_id}
          keyId={'rzp_test_jL3UplbraK01a5'}
          keySecret={'0rbAAXWTiONv2UY37kiQgQmU'}
          currency={'INR'}
          amount={order_data?.response?.[0]?.amount}
          name={formData}
          setPaymentOption={setPaymentOption}
        />
      )}
      <div className='makeandappointmentbg' id='appointment'>
        <section id="appointment" className="appointment section">
          <div className="container section-title text-center" data-aos="fade-up">
            <h3 className='text-center appointmentheading pt-5'>Book An Appointment</h3>
            {/* <h6 className='text-center'>{formData.name}</h6> */}
            <img src={border} className="img-fluid pb-5" alt='border' width={200} />
          </div>
          <div className="container appointmentinputrow">
            <form className="php-email-form" onSubmit={handleSubmitOnline} id="appoitment_form">
              <div className="row">
                <div className="col-md-4 form-group">
                  <label htmlFor="name">Patient's Name</label>
                  <input
                    type="text"
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                    className="form-control"
                    id="name"
                    required
                  />
                  {errors.name && <small className="text-danger">{errors.name}</small>}
                </div>
                <div className="col-md-4 form-group mt-3 mt-md-0">
                  <label htmlFor="age">Patient's Age</label>
                  <input
                    type="number"
                    className="form-control"
                    name="age"
                    value={formData.age}
                    onChange={handleChange}
                    id="age"
                    max="99"
                    required

                  />
                  {errors.age && <small className="text-danger">{errors.age}</small>}
                </div>
                <div className="col-md-4 form-group mt-3 mt-md-0">
                  <label htmlFor="number">Phone Number</label>
                  <input
                    type="tel"
                    name="patient_mobile"
                    // maxLength={10}
                    // minLength={10}
                    onChange={handleChange}
                    className="form-control"
                    value={formData.patient_mobile}
                    id="phonenumber"
                    // pattern="\d{10}"
                    required

                  />
                  {errors.patient_mobile && <small className="text-danger">{errors.patient_mobile}</small>}
                </div>
              </div>
              <div className="row mt-3">
                <div className="col-md-4 form-group">
                  <label htmlFor="illness"> Current Illness</label>
                  <input
                    type="text"
                    name="illness"
                    value={formData.illness}
                    onChange={handleChange}
                    className="form-control"
                    id="illness"
                    required

                  />
                  {errors.illness && <small className="text-danger">{errors.illness}</small>}
                </div>

                <div className="col-md-4 form-group mt-3 mt-md-0">
                  <label htmlFor="address"> Patient's Address</label>
                  <input
                    type="text"
                    name="address"
                    value={formData.address}
                    onChange={handleChange}
                    className="form-control"
                    id="address"
                    required

                  />
                  {errors.address && <small className="text-danger">{errors.address}</small>}
                </div>
                <div className="col-md-4 form-group">
                  <label htmlFor="appointment_type">Consultation Type</label>
                  <select
                    name="appointment_type"
                    id="appointment_type"
                    className="form-select"
                    value={formData.appointment_type}
                    onChange={handleChange}
                    required
                  >
                    <option value="">Choose...</option>
                    {
                      Object.keys(DoctorData).length && Object.keys(DoctorData)?.map((item) => {
                        return <option value={item}>{item}</option>
                      })
                    }

                  </select>
                  {errors.appointment_type && <small className="text-danger">{errors.appointment_type}</small>}
                </div>
              </div>
              <div className="row mt-3">
                <div className="col-md-4 form-group mt-3 mt-md-0">
                  <label htmlFor="city">
                    {
                      formData.appointment_type === 'tele-medicine' ? 'Select Type' : 'Select OPD/City'
                    }
                  </label>
                  <select
                    name="city"
                    id="city"
                    className="form-select"
                    value={formData.city}
                    onChange={handleChange}
                    required
                  >
                    <option value="">Choose...</option>
                    {
                      formData.appointment_type && DoctorData[formData.appointment_type] && Object.keys(DoctorData[formData.appointment_type].location)?.map((item) => {
                        return <option value={item}>{item}</option>
                      })
                    }
                  </select>
                  {errors.city && <small className="text-danger">{errors.city}</small>}
                </div>
                <div className="col-md-4 form-group mt-3 mt-md-0">
                  <label htmlFor="assign_doctor">Select Doctor</label>
                  <select
                    name="assign_doctor"
                    id="assign_doctor"
                    className="form-select"
                    value={formData.assign_doctor}
                    onChange={handleChange}
                    required
                  >
                    <option value="">Choose...</option>
                    {
                      (formData.appointment_type === Object.keys(DoctorData)[0] || formData.appointment_type === Object.keys(DoctorData)[1]) && DoctorData[formData.appointment_type] && DoctorData[formData.appointment_type]['location'][formData.city]?.map((item) => {
                        return <option value={item?.name}>{item?.name}</option>
                      })
                    }
                  </select>
                  {errors.assign_doctor && <small className="text-danger">{errors.assign_doctor}</small>}
                </div>
                <div className="col-md-2 form-group mt-3 mt-md-0">
                  <label htmlFor="appointment_date">Appointment Date</label>
                  <div>
                    <DatePicker
                      selected={formData.appointment_date}
                      onChange={handleDateChange}
                      className="form-control datepicker-custom"
                      name="appointment_date"
                      dateFormat="yyyy-MM-dd"
                      minDate={new Date()}
                      maxDate={new Date(new Date().setMonth(new Date().getMonth() + 2))}
                      required
                      filterDate={
                        (date) => {
                          const formattedDate = date.toLocaleDateString('en-IN');
                          if (availableDates?.[0]?.date === 'all') {
                            const dates = new Date(date);

                            return dates.getUTCDay() !== 6;
                          }
                          return availableDates ? availableDates.some((item) => {

                            const d1 = new Date(item.date)
                            return d1.getTime() === date.getTime()
                          }) : null;
                        }
                      }
                    />
                    {errors.appointment_date && <small className="text-danger">{errors.appointment_date}</small>}
                  </div>


                </div>
                <div className="col-md-2 form-group mt-3 mt-md-0">
                  <label htmlFor="appointment_time">Appointment Time</label>
                  <select
                    name="appointment_time"
                    id="appointment_time"
                    className="form-select"
                    value={formData.appointment_time}
                    onChange={handleChange}
                    required
                  >
                    <option value="">Choose...</option>
                    {availableTimes.map((time, index) => {
                      return <option key={index} value={time} className='with-line' disabled={isDateAndTimeAvailable(formData.appointment_date, time) ? true : false}>{time} {!isDateAndTimeAvailable(formData.appointment_date, time) ? 'availabe' : ''}</option>
                    })}
                  </select>
                  {errors.appointment_time && <small className="text-danger">{errors.appointment_time}</small>}
                </div>
              </div>
              {/* Additional Fields */}
              <div className="form-group mt-3">
                <label htmlFor="message">Message (Optional)</label>
                <textarea
                  className="form-control"
                  name="message"
                  onChange={handleChange}
                  id="message"
                  rows="3"
                  value={formData.message}
                ></textarea>
              </div>
              <div className="text-center py-5">
                <button type="button" className="makeappointmentbtn" onClick={() => openPopUp(true)} disabled={formSubmit}>
                  {formSubmit ? 'Loading.....' : 'Make an Appointment'}
                </button>
              </div>
              {/* model */}
              <div className="modal fade" id="exampleModalCenter" tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered" role="document">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h5 className="modal-title mtitle" id="exampleModalCenterTitle">Complete Payment</h5>
                      <button type="button" className="close" data-bs-dismiss="modal" aria-label="Close" onClick={() => openPopUp(false)}>
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                    <div className="modal-body">
                      <p className='becomemember'>Scan the QR code below to make an appointment</p>
                      <div className='text-center'>
                        {
                          formData?.city == 'Jabalpur' || formData?.city == 'rewa' || formData?.city == 'chhatarpur' || formData?.city == 'narsinghpur' ? <img src={slip500} alt="QR Code" className='img-fluid shadow' width={300} /> : formData?.city == 'pendra' ? <img src={slip400} alt="QR Code" className='img-fluid shadow' width={300} /> : <img src={slip300} alt="QR Code" className='img-fluid shadow' width={300} />
                        }
                      </div>
                      <div className="mt-4">
                        <label htmlFor="screenshot" className="mt-3 mtitle">Please submit a screenshot of your payment:</label>
                        <input type="file" name='image' id="screenshot" onChange={handleChange} className="btn btn-block addbtn mt-2" />
                      </div>
                    </div>
                    <div className="modal-footer">
                      <button type="button" className="btn btn-danger" onClick={() => openPopUp(false)}>Close</button>
                      <button type="submit" className="submitbtndonation">Submit</button>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </section>
      </div>
    </div>
  );
};

export default Make_Appointment;
