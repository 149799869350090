import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { FaFacebook, FaInstagram, FaTwitter, FaWhatsapp } from "react-icons/fa";
import { IoLogoYoutube, IoCall, IoCart } from "react-icons/io5";
import { IoMdMenu } from "react-icons/io";
import logo from '../Assets/images/dphclogo.webp';
import { useDispatch, useSelector } from 'react-redux';
import { logout } from '../slice/userSlice';

const Header = () => {
    const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
    const [isServicesDropdownOpen, setIsServicesDropdownOpen] = useState(false);
    const [isTeamsDropdownOpen, setIsTeamsDropdownOpen] = useState(false);
    const [isMediaDropdownOpen, setIsMediaDropdownOpen] = useState(false);
    const [isUserDropdownOpen, setIsUserDropdownOpen] = useState(false);

    const toggleMobileMenu = () => {
        setIsMobileMenuOpen(!isMobileMenuOpen);
        document.body.classList.toggle('no-scroll', !isMobileMenuOpen);
    };

    const toggleServicesDropdown = () => {
        setIsServicesDropdownOpen(!isServicesDropdownOpen);
        setIsMediaDropdownOpen(false);
    };

    const toggleTeamsDropdown = () => {
        setIsTeamsDropdownOpen(!isTeamsDropdownOpen);
        setIsMediaDropdownOpen(false);
    };

    const toggleMediaDropdown = () => {
        setIsMediaDropdownOpen(!isMediaDropdownOpen);
        setIsServicesDropdownOpen(false);
    };

    const toggleUserDropdown = () => {
        setIsUserDropdownOpen(!isUserDropdownOpen);
    };

    const closeMobileMenu = () => {
        setIsMobileMenuOpen(false);
        setIsServicesDropdownOpen(false);
        setIsMediaDropdownOpen(false);
        setIsUserDropdownOpen(false);
        setIsTeamsDropdownOpen(false);
        document.body.classList.remove('no-scroll');
    };

    const cartData = useSelector((store) => store.cart);
    const userData = useSelector((store) => store.user);
    const dispatch = useDispatch();

    return (
        <div>
            <header id="header" className="header sticky-top">
                <div className="topbar d-flex align-items-center">
                    <div className="container d-flex justify-content-md-center justify-content-md-between">
                        <div className="contact-info d-flex align-items-center text-white">
                            <Link
                                to="mailto:yogidrraghav@gmail.com"
                                target="_blank"
                                rel="noopener noreferrer"
                                className="text-decoration-none text-white ml-lg-1"
                            >
                                yogidrraghav@gmail.com
                            </Link>
                            <IoCall className="d-flex align-items-center ml-3" />
                            <a
                                href="tel:+918516064764"
                                className="text-decoration-none text-white ml-1"
                            >
                                8516064764
                            </a>
                            <a
                                href="tel:+917566064764"
                                className="text-decoration-none text-white ml-3"
                            >
                                7566064764
                            </a>
                        </div>

                        <div className="social-links d-none d-md-flex align-items-center mr-lg-2">
                            <a href="https://twitter.com/PathakCure20979" className="twitter text-white" target="_blank" rel="noopener noreferrer">
                                <FaTwitter />
                            </a>
                            <a href="https://www.facebook.com/Drpathakscure" className="facebook text-white" target="_blank" rel="noopener noreferrer">
                                <FaFacebook />
                            </a>
                            <a href="https://www.instagram.com/drpathaksholisticcure" className="instagram text-white" target="_blank" rel="noopener noreferrer">
                                <FaInstagram />
                            </a>
                            <a href="https://youtube.com/@drpathaksholisticcure?si=QVlXl9WnK0GsvKvg" className="youtube text-white" target="_blank" rel="noopener noreferrer">
                                <IoLogoYoutube />
                            </a>
                            <a href="https://wa.me/917748018010" className="whatsapp text-white" target="_blank" rel="noopener noreferrer">
                                <FaWhatsapp />
                            </a>
                        </div>
                    </div>
                </div>
                <div className="branding d-flex align-items-center">
                    <div className="container position-relative d-flex align-items-center justify-content-between">
                        <Link to="/" className="logo d-flex align-items-center me-auto" onClick={closeMobileMenu}>
                            <img src={logo} alt='drpathaks' className='img-fluid logo' />
                        </Link>
                        <nav id="navmenu" className={`navmenu ${isMobileMenuOpen ? 'open' : ''}`}>
                            <ul>
                                <li className="nav-item">
                                    <Link className="nav-link mymenu" to="/" onClick={closeMobileMenu}>Home</Link>
                                </li>
                                <li className={`nav-item dropdown ${isServicesDropdownOpen ? 'active' : ''}`} onClick={toggleServicesDropdown}>
                                    <span className="nav-link dropdown-toggle mymenu d-flex align-items-center">
                                        Services
                                    </span>
                                    <ul className={`dropdown-menu ${isServicesDropdownOpen ? 'show' : ''}`} aria-labelledby="navbarDropdownMenuLink">
                                        <li><Link className="dropdown-item pl-3" to="/treatment" onClick={closeMobileMenu}>Available Treatment</Link></li>
                                        <li><Link className="dropdown-item pl-3" to="/services" onClick={closeMobileMenu}>Therapies</Link></li>
                                    </ul>
                                </li>
                                <li className="nav-item">
                                    <Link className="nav-link mymenu" to="/medicines" onClick={closeMobileMenu}>Medicines</Link>
                                </li>
                                <li className="nav-item">
                                    <Link className="nav-link mymenu" to="/aboutus" onClick={closeMobileMenu}>About</Link>
                                </li>
                              
                                <li className={`nav-item dropdown ${isTeamsDropdownOpen ? 'active' : ''}`} onClick={toggleTeamsDropdown}>
                                    <span className="nav-link dropdown-toggle mymenu d-flex align-items-center">
                                        Our Team
                                    </span>
                                    <ul className={`dropdown-menu ${isTeamsDropdownOpen ? 'show' : ''}`} aria-labelledby="navbarDropdownMenuLink">
                                        <li><Link className="dropdown-item pl-3" to="/ourdoctors" onClick={closeMobileMenu}>Our Doctors</Link></li>
                                        <li><Link className="dropdown-item pl-3" to="/ourteam" onClick={closeMobileMenu}>Leadership & Team</Link></li>
                                    </ul>
                                </li>
                                <li className={`nav-item dropdown ${isMediaDropdownOpen ? 'active' : ''}`} onClick={toggleMediaDropdown}>
                                    <span className="nav-link dropdown-toggle mymenu d-flex align-items-center">
                                        Media
                                    </span>
                                    <ul className={`dropdown-menu ${isMediaDropdownOpen ? 'show' : ''}`} aria-labelledby="navbarDropdownMenuLink">
                                        <li><Link className="dropdown-item pl-3" to="/photogallery" onClick={closeMobileMenu}>Photo Gallery</Link></li>
                                        <li><Link className="dropdown-item pl-3" to="/medianews" onClick={closeMobileMenu}>Media & News</Link></li>
                                    </ul>
                                </li>
                                {userData?.isLogin ? (
                                    <li className={`nav-item dropdown ${isUserDropdownOpen ? 'active' : ''}`} onClick={toggleUserDropdown}>
                                        <span className="nav-link dropdown-toggle mymenu d-flex align-items-center">
                                            User
                                        </span>
                                        <ul className={`dropdown-menu ${isUserDropdownOpen ? 'show' : ''}`} aria-labelledby="navbarDropdownMenuLinkUser">
                                            <li><Link className="dropdown-item pl-3" to="/myorder" onClick={closeMobileMenu}>My Order</Link></li>
                                            <li><Link className="dropdown-item pl-3" to="/myprofile" onClick={closeMobileMenu}>My Profile</Link></li>
                                            <li><button className="dropdown-item pl-3 bg-danger" onClick={() => { closeMobileMenu(); dispatch(logout()); }}>Logout</button></li>
                                        </ul>
                                    </li>
                                ) : (
                                    <li className="nav-item">
                                        <Link className="nav-link mymenu" to="/login" onClick={closeMobileMenu}>Login</Link>
                                    </li>
                                )}
                                <li className="nav-item">
                                    <Link className="nav-link mymenu" to="/cart" onClick={closeMobileMenu}>
                                        Cart<IoCart className='ml-2' size={20} /><span>{cartData.length}</span>
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <Link className="nav-link mymenu" to="/contact" onClick={closeMobileMenu}>Contact </Link>
                                </li>
                            </ul>
                            
                        </nav>
                        <Link className="cta-btn d-none d-sm-block  ml-5 text-decoration-none" to="/makeappointment">Make an Appointment</Link>
                        <IoMdMenu className="mobile-nav-toggle d-xl-none" onClick={toggleMobileMenu} role="button" aria-label="Toggle mobile menu" />

                    </div>
                </div>
            </header>
        </div>
    );
};

export default Header;
