import React from 'react'
import { SlCalender } from "react-icons/sl";
import { FaUserDoctor } from "react-icons/fa6";
import { IoMdTimer } from "react-icons/io";
import { Link } from 'react-router-dom'
const Quickinfo = () => {
    return (
        <div>
            <div className="">
                <section id="services" className="quickinfo section">
                    <div className="container py-lg-5 mb-5">
                        <div className="row">
                            <div className="col-lg-4 col-md-6 firstcardquick d-flex flex-column  d-md-block" data-aos="fade-up" data-aos-delay="200" style={{ margin: '0', padding: '0' }}>
                                <div className="quickinfo-item missioncard text-start flex-grow-1 d-flex flex-column">
                                    <div className="icon mb-3">
                                        <SlCalender className="quickinfoiocns fw-bolder ml-2" size={45} />
                                    </div>
                                    <div className="flex-grow-1">
                                        <h3 className="mb-3">Don't Hesitate To Contact Us</h3>
                                    </div>
                                    <div className="card-footer mt-auto text-center">
                                        <Link to="/makeappointment">
                                            <button className="quickinfobtn">
                                                Make Appointment
                                            </button>
                                        </Link>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6 quickcard2" data-aos="fade-up" data-aos-delay="300" style={{ marginLeft: '0', marginRight: '0', paddingRight: '0' }}>
                                <div className="quickinfo-item visioncard text-start text-white">
                                    <div className="icon mb-3">
                                        <FaUserDoctor className="quickinfoiocns fw-bolder ml-2 text-white" size={45} />
                                    </div>
                                    <div>
                                        <h3 className="mb-3 text-white">Dr. Pathak Holistic Cure </h3>
                                        <p className="text-justify">believes in a universal approach to medicine based on Homeopathy, Ayurveda, and Naturopathy.</p>
                                    </div>
                                </div>
                            </div>


                            <div className="col-lg-4 col-md-6 quickcard2" data-aos="fade-up" data-aos-delay="400" style={{ marginRight: '0', paddingRight: '0' }}>
                                <div className="quickinfo-item missioncard text-start">
                                    <div className="icon mb-3">
                                        <IoMdTimer className="quickinfoiocns fw-bolder ml-2" size={45} />
                                    </div>
                                    <div>
                                        <a href="#" className="stretched-link text-decoration-none">
                                            <h3 className="mb-3">Service Hours</h3>
                                        </a>
                                        <div className="marquee-container">
                                            <div className="marquee-content">
                                                <p>Monday to Saturday | 10:00 AM - 6:00 PM</p>
                                                <p>Lunch Break | 02:00 PM - 3:00 PM</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>

                    </div>
                </section>
            </div>
        </div>
    )
}

export default Quickinfo