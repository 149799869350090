import React from 'react'
import doctorbanner from '../../Assets/images/docotrpagebanner.png'
import milestone from '../../Assets/images/drpathakji.webp'
import drruchi from '../../Assets/images/ruchiguptamam.jpg'
import drrina from '../../Assets/images/reenamudiya.jpg'

const Ourdoctors = () => {
    return (
        <div>
            <div className="border-0">
                <img className="card-img img-fluid" src={doctorbanner} alt="aboutbanner" />
            </div>
            <div className='container my-lg-5  pb-5 '>
                <div className="container section-title text-center" data-aos="fade-up">
                    <h2 className="text-center aboutheading">Meet Our Expert Doctors</h2>
                    <h6 className="text-center pt-lg-2">
                        Dedicated Professionals Committed to Your Health and Well-being
                    </h6>

                </div>
                {/* founder */}
                <div className='row py-lg-5 px-lg-0 px-3'>
                    <div className='col-md-8 order-2 order-md-1' data-aos="fade-left" data-aos-delay="100">
                        <h6 className="pt-2 fs-4 aboutheading">
                            Dr. Raghav Pathak
                        </h6>
                        <h2 className='serviceheading '>Founder and President</h2>
                        <p className='text-justify servicetext '>
                            Dr. Raghav Pathak is the visionary behind Dr. Pathak's Holistic Cure, with over 15 years of experience in the medical field. Renowned for his expertise in Homoeopathy and Naturopathy, Dr. Pathak has been a pioneer in advancing medical treatments and technologies in the region. His compassionate care and innovative practices have established him as a leader in patient care and hospital management.                        </p>
                        <p className='text-justify servicetext'>
                            Under his leadership, Dr. Pathak's Holistic Cure has become a symbol of hope, blending patient-centric care with cutting-edge technology to improve healthcare outcomes and enhance the quality of life for a wide community.
                        </p>

                        <div className='text-justify py-3'>
                            <h6 className="pt-2 fs-4 aboutheading">Positions Held</h6>
                            <ul>
                                <li>CMD, Dr. Pathak's Holistic Cure</li>
                                <li>Founder and President, Shree Yog and Health Organisation, Khajuraho</li>
                                <li>State President, Integrated Ayush Council, Madhya Pradesh</li>
                            </ul>
                        </div>
                    </div>
                    <div className='col-md-4 text-center order-1 order-md-2 py-4' data-aos="fade-right" data-aos-delay="200">
                        <img className="img-fluid pl-lg-5" src={milestone} alt='Dr Raghav Pathak' width={440} />
                    </div>
                </div>
                {/* awards */}
                <div className='row py-lg-5 d-none'>
                    <div className='' data-aos="fade-up">
                        <h1 className=" fs-2 serviceheading text-center" >Awards</h1>
                        <h6 className="text-center pt-2 pb-5 fs-4 aboutheading">
                            Explore the Awards We’ve Achieved
                        </h6>
                    </div>
                    <div className='col-md-3 mb-4' data-aos="fade-up" data-aos-delay="100">
                        <div className='card h-100 d-flex flex-column'>
                            <img src='https://via.placeholder.com/150' className='card-img-top' alt='AwardImage 1' />
                            <div className='card-body d-flex flex-column'>
                                <h5 className='card-title mt-auto text-center'>Special Guest of Honor Award</h5>
                            </div>
                        </div>
                    </div>

                    <div className='col-md-3 mb-4' data-aos="fade-up" data-aos-delay="200">
                        <div className='card h-100 d-flex flex-column'>
                            <img src='https://via.placeholder.com/150' className='card-img-top' alt='Award Image 2' />
                            <div className='card-body d-flex flex-column'>
                                <h5 className='card-title mt-auto text-center'>Special Guest of Honor</h5>
                            </div>
                        </div>
                    </div>

                    <div className='col-md-3 mb-4' data-aos="fade-up" data-aos-delay="300">
                        <div className='card h-100 d-flex flex-column'>
                            <img src='https://via.placeholder.com/150' className='card-img-top' alt='Award Image 3' />
                            <div className='card-body d-flex flex-column'>
                                <h5 className='card-title mt-auto text-center'>Swabhiman Award for Holistic Approach</h5>
                            </div>
                        </div>
                    </div>

                    <div className='col-md-3 mb-4' data-aos="fade-up" data-aos-delay="400">
                        <div className='card h-100 d-flex flex-column'>
                            <img src='https://via.placeholder.com/150' className='card-img-top' alt='AwardImage4' />
                            <div className='card-body d-flex flex-column'>
                                <h5 className='card-title mt-auto text-center'>Special Guest of Honor by Vice Chancellor</h5>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='row py-lg-5 px-lg-0 px-3'>
                    <div className='col-md-4 text-center order-1 ' data-aos="fade-right" data-aos-delay="200">
                        <img className="img-fluid " src={drruchi} alt='Dr Raghav Pathak' width={380} />
                    </div>
                    <div className='col-md-8 order-2 order-md-1' data-aos="fade-left" data-aos-delay="100">
                        <h6 className="ppt-2 fs-4 aboutheading">
                            Dr. Ruchi Gupta
                        </h6>
                        <h2 className='serviceheading'>Specialist in Homeopathy and Women’s Health</h2>

                        <p className='text-justify servicetext '>
                            Dr. Ruchi Gupta is a distinguished Homeopathic Consultant with expertise in Gynaecology and Female Disorders. Holding a BHMS (Bachelor of Homeopathic Medicine and Surgery) and CGO (Certificate in Gynaecology and Obstetrics), Dr. Gupta brings over a decade of dedication to women’s health and holistic wellness.

                            With a patient-centric approach, Dr. Gupta has been instrumental in addressing complex female health issues through personalized and effective homeopathic treatments. Her compassionate care and commitment to improving women’s health have earned her a trusted reputation among patients and peers alike.
                        </p>
                        <p className='text-justify servicetext'>
                            Dr. Ruchi Gupta’s practice is rooted in the principles of homeopathy, combined with modern advancements in women’s healthcare. Her mission is to empower women by providing accessible, effective, and natural solutions for their health concerns, making a meaningful difference in their lives.
                        </p>



                    </div>

                </div>

                <div className='row py-lg-5 px-lg-0 px-3'>
                    <div className='col-md-8 order-2 order-md-1' data-aos="fade-left" data-aos-delay="100">
                        <h6 className="pt-2 fs-4 aboutheading">
                            Dr. Reena Mudiya
                        </h6>
                        <h2 className='serviceheading '>Expert in Naturopathy and Yoga Therapy
                        </h2>

                        <p className='text-justify servicetext '>
                            Dr. Reena Mudiya is a highly skilled Naturopathy and Yoga Physician, holding a BNYS (Bachelor of Naturopathy and Yogic Sciences). With her dedication to natural healing and holistic wellness, Dr. Mudiya has been transforming lives by promoting health through the integration of yoga and naturopathy.                        </p>
                        <p className='text-justify servicetext'>
                            Her expertise lies in utilizing natural therapies, lifestyle modifications, and yoga practices to address a wide range of health concerns. Dr. Mudiya's approach combines traditional healing methods with modern insights to deliver effective and sustainable health solutions.                        </p>

                        Committed to patient wellness, Dr. Reena Mudiya provides personalized care and treatment plans, ensuring a balance between mind, body, and spirit. Her work reflects her passion for empowering individuals to lead healthier and more fulfilling lives.

                        Reg. No.: 57745

                    </div>
                    <div className='col-md-4 text-center order-1 order-md-2 py-4' data-aos="fade-right" data-aos-delay="200">
                        <img className="img-fluid pl-lg-5" src={drrina} alt='Dr Raghav Pathak' width={440} />
                    </div>
                </div>
            </div>



        </div>
    )
}

export default Ourdoctors
