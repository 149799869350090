import React, { useEffect, useState } from 'react'
import Teambanner from '../../components/Teambanner'
import nishaji from '../../Assets/images/nishamam.jpg'
import madhavpathakji from '../../Assets/images/madhavpathakji.jpg'
import dharmendraji from '../../Assets/images/dharmendraji.jpg'
import pragatisir from '../../Assets/images/pragatisir.jpg'
import axios from 'axios'
const Ourteam = () => {
    const [ourTeam, setOurTeam] = useState([]);
    const getImages = () => {
        axios.get(process.env.REACT_APP_BACKEND_URL + '/ourteam').then((data) => {
            if (data) {
                setOurTeam(data.data)
            }
        })
    }
    useEffect(() => {
        getImages()
    }, [])

    return (
        <div>
            <Teambanner />
            <div className='container my-lg-5  pb-lg-5 pb-9'>
                <div className="container section-title text-center" data-aos="fade-up">
                    <h2 className="text-center aboutheading">Our Milestone</h2>
                    <h6 className="text-center pt-2">
                        Meet the Visionaries and Leaders Shaping Our Success
                    </h6>
                </div>
                {/* founder */}
                <div className='row py-lg-5 px-lg-0 px-3'>
                    <div className='col-md-8 order-2 order-md-1' data-aos="fade-left" data-aos-delay="100">
                        <h6 className="pt-2  aboutheading">
                            Mrs. Nisha Pathak
                        </h6>
                        <h2 className='serviceheading '>The Heart Behind DPHC's Mission</h2>
                        <p className='text-justify servicetext '>
                            Mrs. Nisha Pathak, wife of Dr. Raghav Pathak and daughter-in-law of the late Dr. K. L. Pathak, the founder of Dr. Pathak's Holistic Cure, has been a driving force behind the clinic’s mission. She was deeply inspired by her father-in-law’s lifelong dedication to health, treatment, medicine, traditional formulas, and personal innovations. Nisha played a key role in realizing the importance of DPHC in India, believing that the clinic’s holistic knowledge and practices were needed more by the Indian community than by foreign clients.
                        </p>
                        <p className='text-justify servicetext'>
                            Following the challenges of COVID-19, Nisha encouraged Dr. Raghav Pathak to focus on India, where the demand for such knowledge was greater. She assured him that although wealth and comfort could be achieved abroad, true happiness and satisfaction would come from serving in India. Nisha took on the responsibility of managing patient interactions, preparing medicines, assisting during treatments, and offering personal attention to ensure smooth patient care.
                        </p>
                        <p className='text-justify servicetext'>
                            Nisha’s dedication was essential in providing patients with proper emergency support, guiding them throughout their treatment journey, and helping them fully benefit from the holistic care offered by DPHC. Her unwavering support and commitment have been vital in fulfilling the clinic’s vision and mission.For better understanding, watch the movie <a href="https://youtu.be/hzv49Gp7-sE?si=7SIILkfc7fMSuKTY" target="_blank" rel="noopener noreferrer" className='text-decoration-none'>
                                <span className='virasat'>‘Virasat’</span></a>
                        </p>


                    </div>
                    <div className='col-md-4 text-center order-1 order-md-2 py-4' data-aos="fade-right" data-aos-delay="200">
                        <img className="img-fluid pl-lg-5" src={nishaji} alt='Mrs. Nisha Pathak' width={440} />
                    </div>
                </div>


                <div className='row py-lg-5 px-lg-0 px-3'>
                    <div className='col-md-4 text-center order-1 ' data-aos="fade-right" data-aos-delay="200">
                        <img className="img-fluid " src={madhavpathakji} alt='Advocate Madhav Pathak' width={380} />
                    </div>
                    <div className='col-md-8 order-2 order-md-1' data-aos="fade-left" data-aos-delay="100">
                    <h6 className="pt-2  aboutheading" >
                            Advocate Madhav Pathak
                        </h6>
                        <h2 className='serviceheading '>Legal Advisor, DPHC</h2>

                        <p className='text-justify servicetext '>
                            Advocate Madhav Pathak is a highly respected legal advisor at Dr. Pathak's Holistic Cure (DPHC). With extensive experience in legal practice, he plays a vital role in guiding the organization through legal matters and ensuring that the clinic’s operations remain compliant with all regulations.

                            Alongside his role at DPHC, Advocate Madhav Pathak is also known for running his holistic clinic, which integrates traditional and modern healing methods. His clinic emphasizes a comprehensive approach to patient care, promoting both physical and mental well-being through personalized treatment plans.
                        </p>
                        <p className='text-justify servicetext'>
                            Advocate Madhav Pathak’s work is rooted in his belief that holistic health extends beyond just physical treatments. By providing legal and health solutions with compassion, he is dedicated to improving the overall health and wellness of his patients and community.
                        </p>
                    </div>
                </div>


                <div className='row py-lg-5 px-lg-0 px-3'>
                    <div className='col-md-8 order-2 order-md-1' data-aos="fade-left" data-aos-delay="100">
                    <h6 className="pt-2  aboutheading">
                            Advocate Pragati Pateria
                        </h6>
                        <h2 className='serviceheading '>CEO of DPHC</h2>

                        <p className='text-justify servicetext '>
                            Advocate Pragati Pateria is a prominent legal professional and the CEO of Dr. Pathak's Holistic Cure (DPHC). With her vast expertise in law and strategic leadership, she has played a key role in the growth and success of DPHC, ensuring that the clinic maintains legal compliance while providing holistic healthcare services.
                        </p>
                        <p className='text-justify servicetext'>
                            Under her leadership, DPHC has integrated legal frameworks into its operations, ensuring that patients receive the best care while adhering to the highest legal and ethical standards. Pragati Pateria's efforts have strengthened DPHC's mission to deliver holistic, patient-centered services while maintaining transparency and integrity.
                        </p>

                        <p className='text-justify servicetext'>
                            Pragati’s vision extends beyond the clinic's legal matters; she is passionate about fostering an environment of innovation, collaboration, and community engagement. Her leadership continues to drive DPHC forward as a trusted institution for health and wellness.
                        </p>
                    </div>
                    <div className='col-md-4 text-center order-1 order-md-2 py-4' data-aos="fade-right" data-aos-delay="200">
                        <img className="img-fluid pl-lg-5" src={pragatisir} alt='Advocate Pragati Pateria' width={440} />
                    </div>
                </div>

                <div className='row py-lg-5 px-lg-0 px-3'>
                    <div className='col-md-4 text-center order-1' data-aos="fade-right" data-aos-delay="200">
                        <img className="img-fluid" src={dharmendraji} alt='Dharmendra Pathak Ji' width={380} />
                    </div>
                    <div className='col-md-8 order-2 order-md-1' data-aos="fade-left" data-aos-delay="100">
                    <h6 className="pt-2  aboutheading " id='someteam'>
                            Dharmendra Pathak
                        </h6>
                        <h2 className='serviceheading '>Field Officer - Business Strategy, Growth, and Planning</h2>

                        <p className='text-justify servicetext py-2'>
                            Dharmendra Pathak Ji is an integral part of Dr. Pathak's Holistic Cure and Clinic, specializing in business strategy, growth, and planning. With a keen focus on advancing the clinic’s vision, he excels in formulating and implementing innovative strategies aimed at fostering growth and expansion. Dharmendra's expertise lies in identifying key business opportunities, developing actionable plans, and ensuring sustainable progress to meet organizational milestones.
                        </p>
                        <p className='text-justify servicetext'>
                            His role goes beyond just business development; he aligns the clinic's business objectives with its core value of holistic care. Dharmendra Pathak Ji's commitment to both strategic planning and health services is pivotal in enhancing the clinic's impact on community health, ensuring that Dr. Pathak’s vision is executed with excellence and care.
                        </p>
                    </div>
                </div>

            </div>
            {/* rest team */}
            <div className='serviceback  '>
                <div className='container '>
                    <div className="intro" data-aos="fade-up">
                        <h2 className="text-center aboutheading">Our Team</h2>
                        <h6 className='text-center pt-2 pb-5'> Introducing Our Skilled and Dedicated Staff</h6>
                    </div>
                    <div className="row people py-lg-5 leftspace">
                        {
                            ourTeam.length ? ourTeam.map((item) => {
                                return <div className="col-md-6 col-lg-4 item my-lg-4">
                                    <div className="box">
                                        <img
                                            src={`${process.env.REACT_APP_BACKEND}team/${item.image}`}
                                            alt={item.filename}
                                            width={40}
                                            className="rounded-circle"
                                        />
                                        <h5 className="name">{item.name}</h5>
                                        <p className="title">{item.role}</p>
                                        <p className="description">
                                            {item.description}
                                        </p>
                                    </div>
                                </div>
                            }) : <p className='bg-danger'>No Team Member Found !</p>
                        }
                    </div>
                </div>
            </div>

        </div>
    )
}

export default Ourteam
